import { Box, Button, Typography } from '@mui/material'

import { FormInstructions } from '../../../components'
import { themeColors } from '../../../theme'
import {
  FieldObjectEditable,
  FieldObjectNonEditable,
} from '../../../utilities/Forms/FieldObject.partial'
import { Field, SectionFields } from '../../../utilities/Forms/types'
import { Section } from '../../../utilities/Forms/types'
import { Announcement } from '../../Dashboard/Widgets/Announcement/Announcement.container'

interface AnnouncementsViewProps {
  user: any
  announcementDetailFields: SectionFields
  announcementCreated: boolean
  setAnnouncementCreated: React.Dispatch<React.SetStateAction<boolean>>
  announcementSubmitting: boolean
  setAnnouncementSubmitting: React.Dispatch<React.SetStateAction<boolean>>
}

export const AnnouncementsView = ({
  user,
  announcementDetailFields,
  announcementCreated,
  setAnnouncementCreated,
  announcementSubmitting,
  setAnnouncementSubmitting,
}: AnnouncementsViewProps) => {
  return (
    <Box data-testid='Announcement' sx={{ height: '100%' }}>
      {/* Title Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: '10px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '125%',
          }}
        >
          Announcements View
        </Typography>
      </Box>

      {/* Annoucment Input or Confirmation */}
      <Box
        sx={{
          width: '100%',
          paddingRight: 2,
          justifyContent: 'space-around',
          height: '100%',
        }}
      >
        {!announcementCreated ? (
          <Box
            sx={{
              backgroundColor: themeColors.gray60,
              color: themeColors.white,
              position: 'sticky',
              height: 100,
              fontWeight: 'bold',
              width: '50%',
              padding: '10px 15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
            }}
          >
            Announcement sent to all active patients.
          </Box>
        ) : (
          <Box
            id='column-left'
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            {/* announcement table list */}
            {announcementDetailFields.map((section: Section) => (
              <Box
                key={section.sectionLabel}
                sx={{
                  display: 'flex',
                  width: '50%',
                  height: '100%',
                }}
              >
                <Box
                  sx={{
                    marginBottom: 0.5,
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    {!!section.hasInstructions && announcementCreated && (
                      <FormInstructions isAnnouncement={true} />
                    )}
                  </Box>

                  <Box>
                    {section.fields.map((field: Field) => {
                      const fieldsWithValues: Field = {
                        ...field,
                        initialValue: field.displayKey
                          ? user[field.displayKey]
                          : user[field.backendKey],
                      }
                      return !announcementCreated ? (
                        <FieldObjectNonEditable
                          field={fieldsWithValues}
                          key={field.label}
                        />
                      ) : (
                        <FieldObjectEditable
                          field={fieldsWithValues}
                          key={field.label}
                        />
                      )
                    })}
                  </Box>

                  {!announcementCreated ? (
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        variant='contained'
                        onClick={() => setAnnouncementCreated(true)}
                      >
                        Create a new Announcement
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        margin: '10px',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '10px',
                          width: '100%',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          variant='contained'
                          type='submit'
                          disabled={announcementSubmitting}
                        >
                          Send Announcement
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
            <Box sx={{ minHeight: '700px', width: '80%' }}>
              <Announcement />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
