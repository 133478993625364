import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

import { useMessages } from '../../../../providers/Messages/Messages.provider'
import {
  AttachmentData,
  MessageData,
  MessageThreadData,
  MessageUser,
  UploadObj,
} from '../../../../providers/Messages/types'
import { ProviderUser } from '../../../../providers/ProviderDetails/types'
import { themeColors } from '../../../../theme'
import { getImageUrl } from '../../../../utilities/Storage'
import MessageEditor from './MessageEditor.view'
import { MessageTabs } from './Messaging.container'
import { useStyles } from './Messaging.styles'
import MobileMessage from './MobileMessage.view'

type MessageThreadProps = {
  activeTab: keyof MessageTabs
  messageThread: MessageThreadData
  backToMessages: () => void
  threadId: number
  attachmentKeys?: { id: number; key: string }[]
  setAttachmentKeys?: React.Dispatch<
    React.SetStateAction<{ id: number; key: string }[]>
  >
  uploads: UploadObj[]
  setUploads: React.Dispatch<React.SetStateAction<any>>
  isStandAloneView?: boolean
  myProviderDetails: ProviderUser | null
}

const MessageThread = ({
  activeTab,
  backToMessages,
  // messageThread,
  // threadId,
  attachmentKeys,
  setAttachmentKeys,
  uploads,
  setUploads,
  isStandAloneView,
  myProviderDetails,
}: MessageThreadProps) => {
  const go = useNavigate()

  const { classes } = useStyles()

  const { selectedThreadId, getProviderMessagesInThread, threads } =
    useMessages()

  const [thread, setThread] = useState<MessageThreadData>({
    messageThreadId: 0,
    unread: false,
    messages: [],
    displayUser: {
      name: '',
      role: '',
      photoUrl: '',
      isPatient: false,
      onCareTeam: false,
    },
    displayThreadUserNames: '',
    displayUserCount: 0,
    otherUsers: [],
  })

  useEffect(() => {
    if (selectedThreadId !== null) {
      getProviderMessagesInThread(selectedThreadId, 0)
    }
  }, [selectedThreadId])

  useEffect(() => {
    const indexOfSelectedThread = threads[activeTab].findIndex(
      (threadInThreads) => threadInThreads.messageThreadId === selectedThreadId
    )
    const selectedThread = threads[activeTab].at(indexOfSelectedThread)
    if (selectedThread) {
      setThread(selectedThread)
    }
  }, [selectedThreadId, threads])

  const getAttachmentKeys = async () => {
    const newAttachments: {
      id: number | undefined
      key: string | undefined
    }[] = []
    if (thread.messageThreadId) {
      const allPromises = thread.messages.map(async (message: MessageData) => {
        await Promise.all(
          message.attachments.map(async (a: AttachmentData) => {
            const s3Key = (await getImageUrl(a.storageKey)) || ''
            a.id = message.messageId
            a.key = s3Key
          })
        )
        return message
      })
      const result = (await Promise.all(allPromises))?.map(
        (message: MessageData) =>
          message.attachments.map((a: AttachmentData) => {
            newAttachments.push({
              id: a.id,
              key: a.key,
            })
          })
      )
      // @ts-ignore
      setAttachmentKeys([...newAttachments])
    }
  }
  useEffect(() => {
    getAttachmentKeys()
  }, [thread, selectedThreadId])

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    // @ts-ignore
    messagesEndRef.current?.scrollIntoView({ animated: false })
  }
  useEffect(() => {
    scrollToBottom()
  }, [thread.messages])

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })

  const handleViewChart = async () => {
    // await getPatientDetails(patientId)
    go(`/patientChart/${thread.patientId}`)
  }

  const renderBackBtn = () => {
    const threadPatientNameObj = thread.messages.find(
      (m) => m.fromUser.isPatient
    )
    let patientName = ''
    if (threadPatientNameObj) {
      patientName = threadPatientNameObj.fromUser.name
    } else {
      //in the case that we only have provider initial msg
      patientName = thread.messages[0]?.toUsers[0].name
    }
    return (
      <Grid
        container
        justifyContent='flex-start'
        sx={{
          marginTop: '5px',
          padding: '10px 0',
          backgroundColor:
            isStandAloneView || !isMobile ? themeColors.white : '',
          width: isMobile
            ? isStandAloneView
              ? '93%'
              : '100%'
            : isStandAloneView
            ? '98%'
            : '95%',
        }}
      >
        <button
          type='button'
          onClick={clearStates}
          className={classes.messageThread_backBtn}
        >
          &#8592;
        </button>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '95%',
          }}
        >
          <Typography style={{ marginLeft: 20 }}>{patientName}</Typography>
          <Button onClick={handleViewChart} variant='contained'>
            View Chart
          </Button>
        </div>
      </Grid>
    )
  }

  const clearStates = () => {
    backToMessages()
  }

  const renderReplyBox = () => {
    let threadPatientDeactivated = false
    let deactivatedProviderNameStr = ''
    // thread is with one patient/provider
    if (thread.otherUsers.length === 1) {
      if (thread.otherUsers[0].isPatient) {
        threadPatientDeactivated = thread.otherUsers[0].isActive ? false : true
      } else {
        deactivatedProviderNameStr = thread.otherUsers[0].isActive
          ? ''
          : thread.otherUsers[0].name
      }
    } else {
      const deactivatedArr = thread.otherUsers.filter(
        (u: MessageUser) => !u.isActive
      )
      threadPatientDeactivated = deactivatedArr.some(
        (u: MessageUser) => u.isPatient
      )
      if (!threadPatientDeactivated) {
        const deactivatedProviderArr = deactivatedArr.filter(
          (u: MessageUser) => !u.isPatient
        )
        const deactivatedProviderNameArr = deactivatedProviderArr.map(
          (u: MessageUser) => u.name
        )
        deactivatedProviderNameStr =
          deactivatedProviderNameArr.length > 1
            ? deactivatedProviderNameArr.join(', ')
            : deactivatedProviderNameArr.join('')
      }
    }
    return (
      <Box
        display='flex'
        flexDirection='column'
        margin='15px 0'
        padding='0 10px'
      >
        {threadPatientDeactivated ? (
          <Box
            style={{
              backgroundColor: themeColors.blue,
              padding: 20,
              borderRadius: 10,
            }}
          >
            <Typography
              color={themeColors.white}
              fontSize={18}
              fontWeight={700}
            >
              NOTICE: This patient has been DEACTIVATED and cannot receive
              messages.
            </Typography>
          </Box>
        ) : (
          <>
            {deactivatedProviderNameStr ? (
              <Box
                style={{
                  backgroundColor: themeColors.blue,
                  padding: 20,
                  borderRadius: 10,
                }}
              >
                <Typography color={themeColors.red}>
                  NOTICE: {deactivatedProviderNameStr} is no longer a part of
                  the NorthStar team.
                </Typography>
              </Box>
            ) : null}
            <MessageEditor
              myProviderDetails={myProviderDetails}
              isStandAloneView={isStandAloneView}
              backToMessages={backToMessages}
              uploads={uploads}
              setUploads={setUploads}
              threadId={selectedThreadId !== null ? selectedThreadId : 0}
              thread={thread}
            />
          </>
        )}
      </Box>
    )
  }

  const fetchMoreMessages = () => {
    const oldestMessageId = thread.messages.slice(-1)[0].messageId
    setTimeout(async () => {
      if (selectedThreadId !== null) {
        getProviderMessagesInThread(selectedThreadId, oldestMessageId)
      }
    }, 500)
  }

  const renderMessages = () => {
    return (
      <div
        id='scrollableDiv'
        style={{
          padding: '0 10px',
          paddingTop: isStandAloneView ? '30px' : '',
          width: '100%',
        }}
      >
        <InfiniteScroll
          dataLength={thread.messages.length}
          next={fetchMoreMessages}
          hasMore={
            thread.messages.slice(-1)[0]?.isFirstMessageInThread ? false : true
          }
          inverse
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            padding: '16px',
          }}
          height={600} //crucial for infinite scroll to work
          loader={
            <CircularProgress
              size={20}
              style={{
                color: themeColors.gray40,
                alignSelf: 'center',
                margin: '30px 0',
              }}
            />
          }
          endMessage={
            <p style={{ textAlign: 'center', color: themeColors.gray60 }}>
              <Typography>You have seen all the messages.</Typography>
            </p>
          }
          scrollableTarget='scrollableDiv'
        >
          {thread.messages
            .sort(
              (a, b) =>
                new Date(b.receivedDateTime).getTime() -
                new Date(a.receivedDateTime).getTime()
            )
            .map((item, i) => (
              <Grid container key={`message-${i}`}>
                <MobileMessage
                  myProviderDetails={myProviderDetails}
                  message={item}
                  keyValue={`mp${i}`}
                  attachmentKeys={attachmentKeys}
                />
              </Grid>
            ))}
        </InfiniteScroll>
      </div>
    )
  }

  const renderPage = () => {
    return (
      <div
        style={{
          padding: '8px 0',
          backgroundColor: themeColors.white,
          overflowY: 'scroll',
        }}
      >
        {renderBackBtn()}
        {renderMessages()}

        {renderReplyBox()}
        <div ref={messagesEndRef} />
      </div>
    )
  }

  return renderPage()
}

export default MessageThread
