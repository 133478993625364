import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridFilterModel,
} from '@mui/x-data-grid'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useEffect, useState } from 'react'

import {
  AccessRoleList,
  Provider,
  ProviderList,
} from '../../../../providers/ProvidersProvider/types'
import { themeColors } from '../../../../theme'
import { formatProvidersForTable } from '../utilities'

dayjs.extend(customParseFormat)

const COLUMNS: GridColDef[] = [
  { field: 'providerName', headerName: 'Provider Name', width: 200 },
  { field: 'roles', headerName: 'Provider Role', width: 160 },
  { field: 'accessRoles', headerName: 'Access Role', width: 160 },
  // { field: 'isActive', headerName: 'Active', width: 160 },
].map((col) => ({ ...col, disableColumnMenu: true }))

// NCA-43: 'deactivationDate'
const OPTIONS = COLUMNS.filter((col: GridColDef) =>
  ['providerName', 'roles', 'accessRoles', 'isActive'].includes(col.field)
).map((col: GridColDef) => col.headerName)

interface ListViewProps {
  providers: ProviderList
  providerAccessRoles: AccessRoleList
  selectedProvider: Provider | null
  editProviderModal: boolean
  setEditProviderModal: React.Dispatch<React.SetStateAction<boolean>>
  handleEditProviderModal: (providerId: number) => void
  isEditLoading: boolean
}

export const ListView = ({
  providers,
  selectedProvider,
  handleEditProviderModal,
  isEditLoading,
}: ListViewProps) => {
  const [searchField, setSearchField] = useState<string | null>('Provider Name')
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null)
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })
  const [dataColumns, setDataColumns] = useState<GridColDef[]>(COLUMNS)
  const [pageSize, setPageSize] = useState<number>(20)

  useEffect(() => {
    if (searchField && searchKeyword) {
      setFilterModel({
        items: [
          {
            columnField: dataColumns.find(
              (dc) => dc.headerName === searchField
            )!.field,
            operatorValue: 'contains',
            value: searchKeyword,
          },
        ],
      })
    } else {
      setFilterModel({ items: [] })
    }
  }, [searchField, searchKeyword])

  useEffect(() => {
    const dataColumns = COLUMNS.map((col) => col)
    dataColumns.push({
      field: ' ',
      headerName: ' ',
      width: 120,
      renderCell: (params: GridCellParams) =>
        isEditLoading &&
        selectedProvider &&
        selectedProvider.providerId === Number(params.id) ? (
          <CircularProgress
            size={20}
            style={{
              justifySelf: 'center',
              alignSelf: 'center',
              margin: 'auto',
            }}
          />
        ) : (
          <Button
            id={`edit-role-btn-${Number(params.id)}`}
            sx={{
              minWidth: '100%',
            }}
            variant='contained'
            color='warning'
            onClick={() => {
              handleEditProviderModal(Number(params.id))
            }}
          >
            Edit Access Role
          </Button>
        ),
    })
    setDataColumns(dataColumns)
  }, [providers, isEditLoading, selectedProvider])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: '10px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '125%',
          }}
        >
          Providers View
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '20px 0 30px',
          width: '675px',
        }}
      >
        <Box sx={{ width: '225px' }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '14px',
              marginBottom: '5px',
            }}
          >
            SEARCH BY
          </Typography>
          <Autocomplete
            options={OPTIONS}
            sx={{ width: 'auto' }}
            onChange={(_: any, data: any) => {
              setSearchField(data)
              setSearchKeyword(null)
            }}
            value={searchField}
            renderInput={(params: any) => (
              <TextField
                {...params}
                size='small'
                variant='outlined'
                id='SearchByField'
                placeholder='Select field to search by'
              />
            )}
          />
        </Box>
        {searchField && (
          <Box sx={{ width: '425px' }}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '14px',
                marginBottom: '5px',
              }}
            >
              KEYWORD
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TextField
                size='small'
                variant='outlined'
                sx={{ height: '35px', width: '325px' }}
                onChange={(e) => setSearchKeyword(e.currentTarget.value)}
                value={searchKeyword || ''}
                placeholder={`Type here to search the table below by ${searchField.toLowerCase()}`}
              />
              <Box>
                <Button
                  variant='outlined'
                  sx={{ minWidth: '75px', marginLeft: '20px' }}
                  onClick={() => {
                    setSearchKeyword(null)
                    setSearchField(null)
                  }}
                >
                  Clear
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          height: '100%',
        }}
      >
        <Box sx={{ width: '100%', flexGrow: 1, height: '600px' }}>
          {providers && (
            <DataGrid
              getRowId={(row: any) => row.providerId}
              /*@ts-ignore */
              rows={providers.map((pg: Provider) => {
                return formatProvidersForTable(pg)
              })}
              columns={dataColumns}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50]}
              onPageSizeChange={(v) => setPageSize(v)}
              rowHeight={70}
              filterModel={filterModel}
              disableColumnFilter={true}
              isRowSelectable={() => false}
              sx={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '100%',
                verticalAlign: 'center',
                border: 'none',
                '& .MuiDataGrid-cell:active, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:hover':
                  {
                    border: 'none',
                    outline: 'none',
                  },
                '& .MuiTableCell-root': {
                  border: 'none',
                  outline: 'none',
                },
                '& .MuiDataGrid-cell:hover': {
                  pointer: 'cursor',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-columnHeaderTitleContainer:focus, .MuiDataGrid-columnHeaderTitleContainer:focus-within':
                  {
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '100%',
                    textTransform: 'uppercase',
                    border: 'none',
                    outline: 'none',
                  },
                '& .MuiDataGrid-iconButtonContainer svg': {
                  color: themeColors.blue,
                },
                '& .MuiDataGrid-iconSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within':
                  {
                    border: 'none',
                    outline: 'none',
                  },
                '& .MuiDataGrid-row.even': {
                  backgroundColor: themeColors.gray99,
                },
                '& .MuiDataGrid-row.even:hover, .MuiDataGrid-row.odd:hover': {
                  backgroundColor: themeColors.blue90,
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'name', sort: 'asc' }],
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{
                NoResultsOverlay: () => (
                  <Box
                    sx={{
                      background: themeColors.white,
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '14px',
                        color: themeColors.blue,
                      }}
                    >
                      NO RESULTS
                    </Typography>
                  </Box>
                ),
              }}
            ></DataGrid>
          )}
        </Box>
      </Box>
    </Box>
  )
}
