import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { error } from 'console'
import { useCallback, useEffect, useRef, useState } from 'react'

import { AttachmentData, MessageUser } from '../providers/Messages/types'
import { useUser } from '../providers/User/User.provider'

type WebSocketMessage = {
  type: 'message' | 'notification'
  data: any
}

export type sqsMessage = {
  type: string
  threadId: number
  messageId: number
  messageBody: string
  fromUserId: number
  fromUser: MessageUser
  toUsers: MessageUser[]
  receivedDateTime: string
  attachments: AttachmentData[]
}

export const useWebSocketManager = (url: string) => {
  const ws = useRef<WebSocket | null>(null)
  const [isConnected, setIsConnected] = useState(false)
  const [sqsMessages, setSqsMessages] = useState<sqsMessage[]>([])

  const { token, user } = useUser()

  // Use a ref to track unique message IDs to prevent unnecessary re-renders
  const uniqueMessageIds = useRef<Set<number>>(new Set())

  const reconnectTimeout = useRef<NodeJS.Timeout>()
  const maxReconnectAttempts = 5
  const reconnectAttempts = useRef(0)

  const addMessage = useCallback((message: sqsMessage) => {
    // Check if message is unique
    if (!uniqueMessageIds.current.has(message.messageId)) {
      uniqueMessageIds.current.add(message.messageId)

      setSqsMessages((prevMessages) => {
        const updatedSqsMessages = [...prevMessages, message]
        return updatedSqsMessages
      })
    }
  }, []) // Empty dependency array to prevent recreation

  const connect = () => {
    try {
      if (token) {
        ws.current = new WebSocket(url, ['jwt', token])

        ws.current.onopen = () => {
          console.log('WebSocket Connected')
          setIsConnected(true)
          reconnectAttempts.current = 0

          // Subscribe to SQS messages
          if (ws.current?.readyState === WebSocket.OPEN) {
            ws.current.send(
              JSON.stringify({
                action: 'subscribe',
                data: { messageType: 'userMessages' },
              })
            )
          }
        }

        ws.current.onclose = () => {
          console.log('WebSocket Disconnected')
          setIsConnected(false)

          // Attempt to reconnect if we haven't exceeded max attempts
          if (reconnectAttempts.current < maxReconnectAttempts) {
            reconnectTimeout.current = setTimeout(() => {
              reconnectAttempts.current += 1
              connect()
            }, 3000 * Math.pow(2, reconnectAttempts.current)) // Exponential backoff
          }
        }

        // todo: getting WebSocket Error: {"isTrusted": false, "message": null} repeatedly;
        // need to move on to provider portal so that we can see more details underneath
        ws.current.onerror = (error) => {
          console.error('WebSocket Error:', error)
        }

        ws.current.onmessage = (event) => {
          try {
            const messageFirstParse: WebSocketMessage = JSON.parse(event.data)
            const messageFullyParse = JSON.parse(messageFirstParse.data)

            console.log('ws.current.onmessage is running')
            if (messageFirstParse.type === 'message') {
              // Add the message safely
              addMessage(messageFullyParse.data)
            }
          } catch (error) {
            console.error('Error parsing WebSocket message:', error)
          }
        }
      }
    } catch (error) {
      console.error('Error creating WebSocket connection:', error)
    }
  }

  const disconnect = () => {
    if (reconnectTimeout.current) {
      clearTimeout(reconnectTimeout.current)
    }

    if (ws.current) {
      ws.current.close()
    }
  }

  const sendWebSocketMessage = (message: any) => {
    if (ws.current?.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(message))
    } else {
      console.error('WebSocket is not connected')
    }
  }

  useEffect(() => {
    connect()
    return () => {
      disconnect()
    }
  }, [url, user])

  return {
    isConnected,
    sqsMessages,
    sendWebSocketMessage,
  }
}
