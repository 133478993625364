import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { Input } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Modal } from '../../../../components'
import { useAnnouncement } from '../../../../providers/Announcement/Announcement.provider'
import { AnnouncementListItem } from '../../../../providers/Announcement/types'

interface ViewAnnouncementModalProps {
  showViewAnnouncementModal: boolean
  setShowViewAnnouncementModal: Dispatch<SetStateAction<boolean>>
  selectedAnnouncement: AnnouncementListItem
}

export const ViewAnnouncementModal = ({
  showViewAnnouncementModal,
  setShowViewAnnouncementModal,
  selectedAnnouncement,
}: ViewAnnouncementModalProps) => {
  const { updateAnnouncement } = useAnnouncement()

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [newDescription, setNewDescription] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    selectedAnnouncement && setNewDescription(selectedAnnouncement.description)
  }, [selectedAnnouncement])

  return (
    <Modal
      open={showViewAnnouncementModal && !!selectedAnnouncement.description}
      setOpen={setShowViewAnnouncementModal}
      title={selectedAnnouncement.name}
      width={'50%'}
      ModalContent={
        <Box sx={{ margin: '25px' }}>
          {isEdit && selectedAnnouncement.description ? (
            <Input
              style={{
                width: '100%',
                height: 'auto',
              }}
              multiline
              onChange={(e) => setNewDescription(e.target.value)}
              value={newDescription}
            />
          ) : (
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '19px',
                paddingBottom: '20px',
              }}
            >
              {selectedAnnouncement.description}
            </Typography>
          )}
          <Box
            display={'flex'}
            flexDirection={'row'}
            width={250}
            justifyContent={'space-between'}
            marginTop={2}
          >
            <Button
              id={`edit-role-btn-${Number(selectedAnnouncement.id)}`}
              variant='contained'
              color='warning'
              onClick={async () => {
                if (isEdit) {
                  setIsLoading(true)
                  const updatedData = {
                    topicId: selectedAnnouncement.id,
                    name: selectedAnnouncement.name,
                    description: newDescription,
                  }
                  await updateAnnouncement(updatedData)
                  setIsLoading(false)
                  setShowViewAnnouncementModal(false)
                } else {
                  setIsEdit(true)
                }
              }}
            >
              {isEdit ? (
                isLoading ? (
                  <CircularProgress
                    color='success'
                    size={20}
                    style={{
                      justifySelf: 'center',
                      alignSelf: 'center',
                      margin: 'auto',
                    }}
                  />
                ) : (
                  'Save Changes'
                )
              ) : (
                'Edit'
              )}
            </Button>
            {isEdit ? (
              <Button
                id={`edit-role-btn-${Number(selectedAnnouncement.id)}`}
                variant='contained'
                color='error'
                onClick={() => {
                  setIsEdit(false)
                }}
              >
                Cancel Edit
              </Button>
            ) : null}
          </Box>
        </Box>
      }
    />
  )
}
