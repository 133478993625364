import React, { useEffect, useState } from 'react'

import { useAxios } from '../../utilities/Requests/useAxios'

export type notificationObj = {
  id: number
  patientId: number
  content: string
  isRead: boolean
  date: string
}

export interface NotificationsContextInterface {
  notifications: notificationObj[]
  unreadNotifications: notificationObj[]
  unreadNotificationCount: number
  notificationsEnd: boolean
  unreadNotificationsEnd: boolean
  getNotifications: () => Promise<notificationObj[]>
  getMoreNotifications: (
    oldestNotificationId: number
  ) => Promise<notificationObj[]>
  getUnreadNotifications: () => Promise<notificationObj[]>
  getMoreUnreadNotifications: (
    oldestNotificationId: number
  ) => Promise<notificationObj[]>
  getUnreadNotificationCount: () => Promise<number>
  markNotificationRead: (notificationId: number) => Promise<any>
}

export const NotificationsContext =
  React.createContext<NotificationsContextInterface | null>(null)

export const NotificationsProvider = ({ children }: any) => {
  const { fetch } = useAxios()
  const [notifications, setNotifications] = useState<notificationObj[]>([])
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)
  const [unreadNotifications, setUnreadNotifications] = useState<
    notificationObj[]
  >([])
  const [notificationsEnd, setNotificationsEnd] = useState(false)
  const [unreadNotificationsEnd, setUnreadNotificationsEnd] = useState(false)

  const sortByDate = (notifs: notificationObj[]) => {
    return [...notifs].sort(
      (n1, n2) => new Date(n2.date).getTime() - new Date(n1.date).getTime()
    )
  }

  const deduplicateById = (notifs: notificationObj[]) => {
    const uniqueMap = new Map()
    notifs.forEach((notif) => {
      uniqueMap.set(notif.id, notif)
    })
    return Array.from(uniqueMap.values())
  }

  const getNotifications = async () => {
    const { data, error } = await fetch({
      path: `ProviderNotification/GetProviderNotifications`,
    })

    if (data) {
      setNotifications(sortByDate(data))
      return data
    } else if (error) {
      throw new Error(`Error in getNotifications`)
    }
  }

  const getMoreNotifications = async (oldestPreviousId: number) => {
    const { data, error } = await fetch({
      path: `ProviderNotification/GetMoreProviderNotifications?oldestPreviousId=${oldestPreviousId}`,
    })
    if (data) {
      setNotifications((prev) => {
        const combined = deduplicateById([...prev, ...data])
        return sortByDate(combined)
      })
      if (data.length === 0) {
        setNotificationsEnd(true)
      }
      return data
    } else if (error) {
      throw new Error(`Error in getMoreNotifications`)
    }
  }

  const getUnreadNotifications = async () => {
    const { data, error } = await fetch({
      path: `ProviderNotification/GetUnreadProviderNotifications`,
    })

    if (data) {
      setUnreadNotifications(sortByDate(data))
      return data
    } else if (error) {
      throw new Error(`Error in getUnreadNotifications`)
    }
  }

  const getMoreUnreadNotifications = async (oldestPreviousId: number) => {
    const { data, error } = await fetch({
      path: `ProviderNotification/GetMoreUnreadProviderNotifications?oldestPreviousId=${oldestPreviousId}`,
    })
    if (data) {
      setUnreadNotifications((prev) => {
        const combined = deduplicateById([...prev, ...data])
        return sortByDate(combined)
      })
      if (data.length === 0) {
        setUnreadNotificationsEnd(true)
      }
      return data
    } else if (error) {
      throw new Error(`Error in getMoreUnreadNotifications`)
    }
  }

  const getUnreadNotificationCount = async () => {
    const { data, error } = await fetch({
      path: `ProviderNotification/GetUnreadProviderNotificationsCount`,
    })
    if (data) {
      setUnreadNotificationCount(data)
      return data
    } else if (error) {
      throw new Error(`Error in getUnreadNotificationCount`)
    }
  }

  const markNotificationRead = async (notificationId: number) => {
    const { error } = await fetch({
      path: `ProviderNotification/MarkProviderNotificationRead?notificationId=${notificationId}`,
      methodType: 'PUT',
    })

    if (error) {
      throw new Error(`Error in markNotificationRead`)
    }

    setNotifications((prev) =>
      prev.map((notif) =>
        notif.id === notificationId ? { ...notif, isRead: true } : notif
      )
    )

    setUnreadNotifications((prev) =>
      prev.filter((notif) => notif.id !== notificationId)
    )

    setUnreadNotificationCount((prev) => Math.max(0, prev - 1))
  }

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        unreadNotifications,
        notificationsEnd,
        unreadNotificationsEnd,
        getNotifications,
        getUnreadNotifications,
        getMoreUnreadNotifications,
        unreadNotificationCount,
        getUnreadNotificationCount,
        getMoreNotifications,
        markNotificationRead,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}
