/* eslint-disable no-control-regex */
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify'
import { FC, useEffect, useState } from 'react'
import { Location, useLocation, useNavigate } from 'react-router-dom'

import CreatePassword from '../../components/CreatePassword/CreatePassword'
import {
  useAuth,
  usePasswordFunctions,
  useSignOut,
  useSignUpFunctions,
} from '../../providers/User/User.hooks'
import { HelperFuncs } from '../../utilities'
import { useAxios } from '../../utilities/Requests/useAxios'
import { AuthView } from './Auth.view'

export type Step = {
  name: string
  header: string
  helpText: string
  note?: string
  fields: {
    id: string
    label: string
    required?: boolean
    pattern?: RegExp
    isCode?: boolean
    value?: string | null
    type?: string
  }[]
  component?: (props: ComponentProps) => JSX.Element
  errorText: (submitCount: number) => string
  action: (data: any) => Promise<any>
  actionText?: string
  tangentialAction?: {
    label: string
    action: () => Promise<any>
    errorText: (submitCount: number) => string
  }
  redirectAction?: {
    label: string
    action: () => Promise<any>
  }
  initializeAction?: () => Promise<any>
}

type ComponentProps = {
  setIsDisabled: (isDisabled: boolean) => void
  clearErrors: () => void
}

const EMAIL_STORAGE_KEY = 'nsc.email'
const STEP_STORAGE_KEY = 'nsc.auth.step'
const CODE_STORAGE_KEY = 'nsc.tmp.code'
const FINAL_VALIDATION_STORAGE_KEY = 'nsc.auth.v.code'

const AuthContainer: FC = () => {
  const { route } = useAuthenticator()
  const location: Location = useLocation()
  const go = useNavigate()
  const [email, setEmail] = useState<string | null>(null)
  const [tempPass, setTempPass] = useState<string | null>(null)
  const [password, setPassword] = useState<string | null>(null)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [lastFourPhoneNumber, setLastFourPhoneNumber] = useState<string | null>(
    null
  )

  const [activeStepIndex, setActiveStepIndex] = useState<number>(0)

  const { signIn } = useAuth()
  const { fetch } = useAxios()

  const { signUp, confirmSignUp, resendSignUp } = useSignUpFunctions()
  const { resetPassword, forgotPassword, changePassword } =
    usePasswordFunctions()

  const from: string = location.state?.from?.pathname || '/home'
  useEffect(() => {
    const activeLoginStep = localStorage.getItem(STEP_STORAGE_KEY)
      ? Number(localStorage.getItem(STEP_STORAGE_KEY)) || 0
      : 0
    const validateSystemConnection = async () => {
      try {
        if (
          localStorage.getItem(FINAL_VALIDATION_STORAGE_KEY) ||
          !activeLoginStep
        ) {
          go(from, { replace: true, state: location.state })
        }
      } catch {
        setActiveStepIndex(activeLoginStep)
      }
    }

    let interval: any
    if (route === 'authenticated') {
      validateSystemConnection()
      interval = setInterval(() => {
        Auth.currentSession()
      }, 6000)
    } else if (route === 'signOut') {
      clearInterval(interval)
      setActiveStepIndex(0)
    } else {
      setActiveStepIndex(activeLoginStep)
    }
  }, [route, go, from])

  useEffect(() => {
    localStorage.setItem(STEP_STORAGE_KEY, `${activeStepIndex}`)
  }, [activeStepIndex])

  useEffect(() => {
    if (tempPass) {
      localStorage.setItem(CODE_STORAGE_KEY, tempPass)
    }
  }, [tempPass])

  enum Steps {
    Login = 'Login',
    SignUpEnterEmail = 'SignUpEnterEmail',
    SignUpAccessCode = 'SignUpAccessCode',
    SignUpChallengeCode = 'SignUpChallengeCode',
    SignUpPassword = 'SignUpPassword',
    ForgotPasswordEnterEmail = 'ForgotPasswordEnterEmail',
    ForgotPasswordNewPass = 'ForgotPasswordNewPass',
    SetupComplete = 'SetupComplete',
    CreatePassword = 'CreatePassword',
  }

  const steps: Step[] = [
    {
      name: Steps.Login,
      header: 'North-Star Care Provider Portal',
      helpText: '',
      fields: [
        {
          id: 'email',
          label: 'Email',
          value: localStorage.getItem(EMAIL_STORAGE_KEY),
        },
        {
          id: 'password',
          label: 'Password',
          type: 'password',
        },
      ],
      errorText: (submitCount) =>
        submitCount < 2
          ? `Incorrect login information.`
          : `Incorrect login information. Please call North-Star Care support at ${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}.`,
      action: async ({ email, password }) => {
        setIsDisabled(true)
        localStorage.setItem(EMAIL_STORAGE_KEY, email)
        try {
          await signIn({ email, password })
          window.location.href = from
        } catch (err) {
          throw new Error('Invalid')
        } finally {
          setIsDisabled(false)
        }
      },
      actionText: 'Login',
      tangentialAction: {
        label: 'Forgot Password?',
        action: async () => {
          setActiveStepIndex(
            steps.findIndex((x) => x.name === Steps.ForgotPasswordEnterEmail)
          )
        },
        errorText: () => ``,
      },
      redirectAction: {
        label: 'First time?',
        action: async () => {
          localStorage.clear()
          setActiveStepIndex(
            steps.findIndex((x) => x.name === Steps.SignUpEnterEmail)
          )
        },
      },
    },
    {
      name: Steps.ForgotPasswordEnterEmail,
      header: 'Forgot Password',
      helpText:
        'Please enter the email address associated with your North-Star Care account.',
      fields: [
        {
          id: 'email',
          label: 'Email',
          value: localStorage.getItem(EMAIL_STORAGE_KEY),
        },
      ],
      errorText: (submitCount) =>
        submitCount < 2
          ? `Incorrect email address.`
          : `Incorrect email address. Please call North-Star Care support at ${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}.`,
      action: async ({ email }) => {
        setIsDisabled(true)
        setEmail(email)
        localStorage.setItem(EMAIL_STORAGE_KEY, email)
        try {
          await forgotPassword({ email })
          setActiveStepIndex(activeStepIndex + 1)
        } catch (err) {
          throw new Error('Invalid')
        } finally {
          setIsDisabled(false)
        }
      },
      redirectAction: {
        label: 'Back to login',
        action: async () => {
          setActiveStepIndex(steps.findIndex((x) => x.name === Steps.Login))
        },
      },
    },
    {
      name: Steps.ForgotPasswordNewPass,
      header: 'Forgot Password',
      helpText: `An email has been sent to ${email}. Enter the verification code and your new password.`,
      fields: [
        {
          id: 'forgotPasswordAccessCode',
          label: 'Verification Code',
          required: true,
          pattern: /[\S]{6}/,
          isCode: true,
        },
        {
          id: 'newPassword',
          label: 'New Password',
          required: true,
          type: 'password',
        },
      ],
      errorText: (submitCount) =>
        submitCount < 2
          ? `Incorrect email address.`
          : `Incorrect email address. Please call North-Star Care support at ${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}.`,
      action: async ({ forgotPasswordAccessCode, newPassword }) => {
        if (email) {
          setIsDisabled(true)
          try {
            await resetPassword({
              email,
              password: newPassword,
              code: forgotPasswordAccessCode,
            })
            setActiveStepIndex(steps.findIndex((x) => x.name === Steps.Login))
          } catch (err) {
            throw new Error('Invalid')
          } finally {
            setIsDisabled(false)
          }
        }
      },
    },
    {
      name: Steps.SignUpEnterEmail,
      header: 'Account Setup',
      helpText:
        'Please enter the email address associated with your North-Star Care account.',
      fields: [
        {
          id: 'email',
          label: 'Email',
          required: true,
          pattern:
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
        },
      ],
      errorText: (submitCount) =>
        submitCount < 2
          ? `Incorrect email address. Please try again with your North-Star Care email address provided.`
          : `Incorrect email address. Please call North-Star Care support at ${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}.`,
      action: async ({ email }) => {
        setIsDisabled(true)
        setEmail(email)
        localStorage.setItem(EMAIL_STORAGE_KEY, email)
        try {
          const tmp = await signUp({ email })
          setTempPass(tmp)
          setIsDisabled(false)
          setActiveStepIndex(activeStepIndex + 1)
        } catch (err: any) {
          if (
            err.message === 'An account with the given email already exists.'
          ) {
            try {
              await Auth.resendSignUp(email)
              const tmpForUnverifiedEmail =
                HelperFuncs.generateRandomPassword(12)

              setTempPass(tmpForUnverifiedEmail)
              setActiveStepIndex(activeStepIndex + 1)
            } catch (error: any) {
              throw new Error('Invalid')
            }
          }
          throw new Error('Invalid')
        } finally {
          setIsDisabled(false)
        }
      },
      redirectAction: {
        label: 'Back to login',
        action: async () => {
          setActiveStepIndex(steps.findIndex((x) => x.name === Steps.Login))
        },
      },
    },
    {
      name: Steps.SignUpAccessCode,
      header: 'Validate Email Address',
      helpText:
        'If North-Star Care recognizes the provided email address, our system will send a temporary confirmation code.  Please enter the code below.',
      note: 'NOTE: If you do not receive an email within 2-3 minutes, please check your spam/junk folder.',
      fields: [
        {
          id: 'accessCode',
          label: 'Confirmation Code',
          required: true,
          pattern: /[\S]{6}/,
          isCode: true,
        },
      ],
      errorText: (submitCount) =>
        submitCount < 2
          ? `Incorrect Confirmation Code.`
          : `Incorrect Confirmation Code.  Please click "Resend Confirmation Code" below.`,
      action: async ({ accessCode }) => {
        const emailFromStorage = localStorage.getItem(EMAIL_STORAGE_KEY)
        const tempPassFromStorage = localStorage.getItem(CODE_STORAGE_KEY)

        if (emailFromStorage && tempPassFromStorage) {
          setIsDisabled(true)
          try {
            await confirmSignUp({
              email: emailFromStorage,
              code: accessCode,
              password: tempPassFromStorage,
            })
            setActiveStepIndex(activeStepIndex + 1)
          } catch (err) {
            throw new Error('Invalid')
          } finally {
            setIsDisabled(false)
          }
        }
      },
      tangentialAction: {
        label: 'Resend code',
        action: async () => {
          const emailFromStorage = localStorage.getItem(EMAIL_STORAGE_KEY)

          if (emailFromStorage) {
            try {
              await resendSignUp({ email: emailFromStorage })
            } catch (err) {
              throw new Error('Invalid')
            }
          }
        },
        errorText: (submitCount) =>
          submitCount < 2
            ? `Unable to send new Access Code. Please try again.`
            : `Unable to send new Access Code. Please call North-Star Care support at ${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}.`,
      },
    },
    {
      name: Steps.SignUpChallengeCode,
      header: 'Verify Phone #',
      helpText: `Success! Next, let's verify your phone number.${
        lastFourPhoneNumber
          ? ' Please enter the 6 digit verification code sent to (XXX) XXX - ' +
            lastFourPhoneNumber +
            '.'
          : ''
      }`,
      fields: [
        {
          id: 'challengeResponse',
          label: 'Verification Code',
          required: true,
          pattern: /[\S]{6}/,
          isCode: true,
        },
      ],
      errorText: (submitCount) =>
        submitCount < 2
          ? `Incorrect Verification Code.`
          : `Incorrect Verification Code.  Please tap "Resend verification code" below`,
      initializeAction: async () => {
        try {
          const { data } = await fetch({
            path: 'Account/GetLastFourOfPhoneNumber',
          })

          if (data) {
            setLastFourPhoneNumber(data)
          }

          await fetch({
            path: 'Account/CheckMobilePhone',
            methodType: 'POST',
            body: { challengeResponse: '' },
          })
        } catch (err) {
          throw new Error('Invalid')
        }
      },
      action: async ({ challengeResponse }) => {
        if (challengeResponse) {
          setIsDisabled(true)

          try {
            await fetch({
              path: 'Account/CheckMobilePhone',
              methodType: 'POST',
              body: { challengeResponse },
            })
            setActiveStepIndex(activeStepIndex + 1)
          } catch (err) {
            throw new Error('Invalid')
          } finally {
            setIsDisabled(false)
          }
        }
      },
      tangentialAction: {
        label: 'Resend verification code',
        action: async () => {
          try {
            const { data } = await fetch({
              path: 'Account/CheckMobilePhone',
              methodType: 'POST',
              body: { challengeResponse: '' },
            })
            return data
          } catch (err) {
            throw new Error('Invalid')
          }
        },
        errorText: (submitCount) =>
          submitCount < 2
            ? `Unable to send new Access Code. Please try again.`
            : `Unable to send new Access Code. Please call North-Star Care support at ${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}.`,
      },
    },
    {
      name: Steps.CreatePassword,
      header: 'Create Password',
      helpText:
        'Perfect!  Now let’s create a strong password to access your account in the future.',
      fields: [],
      component: (props) => (
        <CreatePassword setPassword={setPassword} {...props} />
      ),
      errorText: (submitCount) =>
        submitCount < 2
          ? `Unable to create account. Please try again.`
          : `Unable to create account. Please call North-Star Care support at ${process.env.REACT_APP_SUPPORT_PHONE_NUMBER}.`,
      action: async () => {
        const tmp = tempPass || localStorage.getItem(CODE_STORAGE_KEY)

        if (!password || !tmp) {
          throw new Error('Invalid')
        }

        setIsDisabled(true)
        try {
          await changePassword({
            password: tmp,
            newPassword: password,
          })
          localStorage.removeItem(STEP_STORAGE_KEY)
          localStorage.removeItem(CODE_STORAGE_KEY)

          localStorage.setItem(FINAL_VALIDATION_STORAGE_KEY, tmp)
          setActiveStepIndex(activeStepIndex + 1)
        } catch (err) {
          throw new Error('Invalid')
        } finally {
          setIsDisabled(false)
        }
      },
    },
    {
      name: Steps.SetupComplete,
      header: 'Account Setup Complete!',
      helpText:
        'You have successfully created a North-Star Care provider portal account.  Click Complete below to login.',
      errorText: () => '',
      action: async () => {
        window.location.href = '/home'
      },
      fields: [],
      actionText: 'Complete',
    },
  ]

  return (
    <AuthView
      steps={steps}
      activeStepIndex={activeStepIndex}
      setActiveStepIndex={setActiveStepIndex}
      isDisabled={isDisabled}
      setIsDisabled={setIsDisabled}
    />
  )
}

const Logout = () => {
  const signOut = useSignOut()
  const go = useNavigate()

  useEffect(() => {
    signOut()
    localStorage.removeItem(STEP_STORAGE_KEY)
    go('/')
  })

  return <></>
}

export { AuthContainer as Auth, Logout }
