import { DataThresholding, Diversity1 } from '@mui/icons-material'
import { useEffect, useState } from 'react'

import { AnnouncementProvider } from '../../providers/Announcement/Announcement.provider'
import { useProviderDetails } from '../../providers/ProviderDetails/ProviderDetails.provider'
import { ProvidersProvider } from '../../providers/ProvidersProvider/ProvidersProvider.provider'
import { Providers } from '../Admin/SuperAdmin/Providers.container'
import { AdminView } from './Admin.view'
import { Announcements } from './Announcements/Announcements.container'
import { AdminSubview } from './types'

export interface AdminViewBaseProps {
  menuItems: AdminSubview[]
  setSelectedMenuItem: React.Dispatch<React.SetStateAction<AdminSubview | null>>
  selectedMenuItem: AdminSubview | null
}

const Admin = () => {
  const { myProviderDetails } = useProviderDetails()

  const [providerAccessRole, setProviderAccessRole] = useState({
    id: 0,
    description: '',
  })

  useEffect(() => {
    if (myProviderDetails?.accessRoles) {
      setProviderAccessRole(myProviderDetails?.accessRoles[0])
    }
  }, [myProviderDetails])

  const ADMIN_LINKS: AdminSubview[] = [
    // {
    //   Icon: <Settings />,
    //   title: 'General',
    //   restricted: true,
    // },
    // {
    //   Icon: <RateReview />,
    //   title: 'Messages',
    //   restricted: true,
    // },
    // {
    //   Icon: <EventNote />,
    //   title: 'Schedule',
    //   restricted: true,
    // },
    // {
    //   Icon: <Assignment />,
    //   title: 'Tasks',
    //   restricted: true,
    // },

    {
      Icon: <DataThresholding />,
      title: 'Announcements',
      View: <Announcements />,
      Provider: AnnouncementProvider,
      restricted: !(
        providerAccessRole?.description === 'SuperAdmin' ||
        providerAccessRole?.description === 'Admin'
      ),
    },
    {
      Icon: <Diversity1 />,
      title: 'Providers',
      View: <Providers />,
      Provider: ProvidersProvider,
      restricted: !(providerAccessRole?.description === 'SuperAdmin'),
    },

    // {
    //   Icon: <DataThresholding />,
    //   title: 'Report',
    //   restricted: true,
    // },
    // NCA-113
  ]

  const [selectedMenuItem, setSelectedMenuItem] = useState<AdminSubview | null>(
    ADMIN_LINKS[0]
  )

  const [nextMenuItem, setNextMenuItem] = useState<AdminSubview | null>(null)

  useEffect(() => {
    if (!selectedMenuItem) {
      setSelectedMenuItem(nextMenuItem)
    }
  }, [selectedMenuItem])

  return selectedMenuItem?.Provider ? (
    <selectedMenuItem.Provider>
      <AdminView
        menuItems={ADMIN_LINKS}
        {...{ selectedMenuItem, setSelectedMenuItem, setNextMenuItem }}
      />
    </selectedMenuItem.Provider>
  ) : (
    <AdminView
      menuItems={ADMIN_LINKS}
      {...{ selectedMenuItem, setSelectedMenuItem, setNextMenuItem }}
    />
  )
}

export { Admin }
