import {
  AccountCircle,
  Dashboard,
  ExitToApp,
  Group as GroupIcon,
  KeyboardArrowDown,
  Logout,
  Menu as MenuList,
  MoreVert as MoreVertIcon,
  Notifications as NotificationsIcon,
  OnlinePrediction,
  Shield as ShieldIcon,
} from '@mui/icons-material'
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Toolbar,
  Typography,
} from '@mui/material'
import { MouseEvent, useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { UserNavLink } from '../../providers/Navigation/types'
import {
  NotificationsContext,
  NotificationsContextInterface,
} from '../../providers/Notifications/NotificationsProvider'
import { ProviderDetailsContext } from '../../providers/ProviderDetails/ProviderDetails.provider'
import { ProviderUser } from '../../providers/ProviderDetails/types'
import { VrHeadsetProvider } from '../../providers/VrHeadset/VrHeadset.provider'
import { themeColors } from '../../theme'
import { Profile } from '../../views/Profile/Profile.container'
import { NotificationsDisplay } from '../Notifications/NotificationsDisplay'
import BannerLogo from './../../assets/BannerLogo.png'
import { useStyles } from './Header.styles'

export type HeaderProps = {
  pages?: UserNavLink[]
  user: ProviderUser | null
}

export const Header = ({ pages = [], user }: HeaderProps) => {
  const { classes } = useStyles()
  const { myProviderDetails, updateProviderOnlineStatus } = useContext(
    ProviderDetailsContext
  )

  const {
    getNotifications,
    getUnreadNotificationCount,
    unreadNotificationCount,
  } = useContext(NotificationsContext) as NotificationsContextInterface

  useEffect(() => {
    getNotifications()
    getUnreadNotificationCount()
  }, [])

  const UserMenuLinks: UserNavLink[] = [
    {
      Icon: <AccountCircle />,
      title: 'Profile',
      route: 'account',
      View: <Profile />,
      Provider: VrHeadsetProvider,
    },
    {
      Icon: <ExitToApp />,
      title: 'Log Out',
      route: 'logout',
      View: <Logout />,
    },
    {
      Icon: <OnlinePrediction />,
      title: myProviderDetails?.isOnline ? 'Set as Inactive' : 'Set as Active',
      route: 'home',
      View: <Dashboard />,
    },
  ]

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null)

  // Add state for notifications popover
  const [notificationsAnchorEl, setNotificationsAnchorEl] =
    useState<HTMLElement | null>(null)
  const notificationsOpen = Boolean(notificationsAnchorEl)

  const handleOpenUserMenu = (event: MouseEvent) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  // Add handlers for notifications popover
  const handleOpenNotifications = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationsAnchorEl(event.currentTarget)
    // Stop propagation to prevent triggering the user menu
    event.stopPropagation()
  }

  const handleCloseNotifications = () => {
    setNotificationsAnchorEl(null)
  }

  return (
    <div data-testid='header'>
      <AppBar position='static'>
        <Toolbar disableGutters className={classes.toolbar}>
          <Box
            sx={{
              display: { xs: 'none', lg: 'block' },
              mr: 1,
            }}
            className={classes.logoContainer}
          >
            <img src={BannerLogo} alt='' />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'block', lg: 'none' } }}>
            <IconButton
              size='large'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='secondary'
            >
              <MenuList fontSize='small' color='secondary' />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', lg: 'none' },
              }}
            >
              {pages
                .filter((page) => !page.restricted && !page.hiddenFromNav)
                .map((page, i) => (
                  <NavLink
                    to={'/' + page.route}
                    key={`page-${i}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign='center'>{page.title}</Typography>
                    </MenuItem>
                  </NavLink>
                ))}
            </Menu>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex', lg: 'none' },
              mr: 1,
              flexGrow: 6,
              justifyContent: 'center',
            }}
          >
            <img src={BannerLogo} alt='' className={classes.logoContainer} />
          </Box>

          <Box sx={{ flexGrow: 6, display: { xs: 'none', lg: 'flex' } }}>
            {pages
              .filter((page) => !page.restricted && !page.hiddenFromNav)
              .map((page, i) => (
                <NavLink
                  to={'/' + page.route}
                  key={`page-${i}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign='center'>{page.title}</Typography>
                  </MenuItem>
                </NavLink>
              ))}
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {/* Container for notifications and user info, aligned together */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* Bell icon with notification badge - positioned just before the Hello text */}
              <Box
                sx={{
                  position: 'relative',
                  marginRight: '25px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={handleOpenNotifications}
                aria-describedby={
                  notificationsOpen ? 'notifications-popover' : undefined
                }
              >
                <NotificationsIcon color='secondary' fontSize='medium' />
                {/* Notification badge */}
                {unreadNotificationCount > 0 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      right: -6,
                      transform: 'translateY(-100%)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid transparent',
                      borderRadius: '50%',
                      backgroundColor: 'red',
                      color: 'white',
                      fontSize: '11px',
                      fontWeight: 'bold',
                      minWidth: '18px',
                      height: '18px',
                      padding: '0 4px',
                    }}
                  >
                    {unreadNotificationCount}
                  </Box>
                )}
              </Box>

              {/* User info section */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                }}
                onClick={handleOpenUserMenu}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography fontSize={14} fontWeight={700}>
                    Hello, {user?.preferredFirstName || user?.firstName}
                  </Typography>
                </Box>

                <KeyboardArrowDown
                  sx={{ marginLeft: '10px' }}
                  fontSize='medium'
                  color='secondary'
                />
                <Avatar
                  alt={user?.preferredFirstName || user?.firstName}
                  src={user?.photoUrl}
                  style={{ marginLeft: '25px', position: 'relative' }}
                />
                {myProviderDetails?.isOnline ? (
                  <span
                    style={{
                      position: 'absolute',
                      right: '15px',
                      bottom: '10px',
                      padding: '6px',
                      borderRadius: '10px',
                      backgroundColor: themeColors.green,
                    }}
                  />
                ) : null}
              </Box>
            </Box>

            {/* Notifications Popover */}
            <Popover
              id='notifications-popover'
              open={notificationsOpen}
              anchorEl={notificationsAnchorEl}
              onClose={handleCloseNotifications}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                sx: {
                  width: 320,
                  maxWidth: '100%',
                  maxHeight: 'calc(100% - 100px)',
                  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                  borderRadius: '8px',
                  mt: 1,
                },
              }}
            >
              <NotificationsDisplay
                handleCloseNotifications={handleCloseNotifications}
              />
            </Popover>

            {!!UserMenuLinks.length && (
              <Menu
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {UserMenuLinks.filter(
                  (page) => !page.restricted && !page.hiddenFromNav
                ).map((page) => {
                  if (page.title.includes('Set as')) {
                    return (
                      <MenuItem
                        key={`subroute-${page.title}`}
                        onClick={async () => {
                          const currentOnlineStatus =
                            myProviderDetails?.isOnline
                          await updateProviderOnlineStatus(!currentOnlineStatus)
                          handleCloseUserMenu()
                        }}
                      >
                        <ListItemIcon>{page.Icon}</ListItemIcon>
                        <ListItemText secondary={page.title} />
                      </MenuItem>
                    )
                  } else {
                    return (
                      <NavLink
                        to={'/' + page.route}
                        key={`subroute-${page.route}`}
                        style={{
                          textDecoration: 'none',
                          color: 'inherit',
                          fontSize: 12,
                        }}
                      >
                        <MenuItem onClick={handleCloseUserMenu}>
                          <ListItemIcon>{page.Icon}</ListItemIcon>
                          <ListItemText secondary={page.title} />
                        </MenuItem>
                      </NavLink>
                    )
                  }
                })}
              </Menu>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  )
}
