import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { TimeZoneOptions } from '../../utilities/Mocking/Options'
import { useAxios } from '../../utilities/Requests/useAxios'
import { getImageUrl } from '../../utilities/Storage'
import {
  BackgroundPhotoOption,
  Credentials,
  MeetingCategories,
  Options,
  OptionsContainers,
  OptionsContextInterface,
  PatientOption,
  ProviderOption,
  States,
  TimeZones,
} from './types'

export const OptionsContext = createContext<OptionsContextInterface>({
  options: {
    providerCredentials: [],
    states: [],
    statesWithoutId: [],
    meetingCategories: [],
    providers: [],
    providerUsers: [],
    patients: [],
    activePatients: [],
    timeZones: [],
    scheduleFrequencies: [],
    peerGroupBackgrounds: [],
  },
  optionsLoading: false,
  reloadOptions: () => {},
})

export const OptionsProvider = ({ children }: { children?: ReactNode }) => {
  const [options, setOptions] = useState<OptionsContainers>({
    providerCredentials: [],
    states: [],
    statesWithoutId: [],
    meetingCategories: [],
    providers: [],
    providerUsers: [],
    patients: [],
    activePatients: [],
    timeZones: [],
    scheduleFrequencies: [],
    peerGroupBackgrounds: [],
  })
  const [optionsLoading, setOptionsLoading] = useState(false)
  const { fetch } = useAxios()

  const getCredentialsList = useCallback(async () => {
    const { data } = await fetch({
      path: 'Options/GetCredentialsList',
    })
    return data?.map((cr: Credentials, i: number) => ({
      ...cr,
      label: `${cr.longName} (${cr.abbreviation})`,
      value: cr,
      apiId: cr.id,
    }))
  }, [])

  const getStates = useCallback(async (useApiId = true) => {
    const { data } = await fetch({
      path: 'Options/GetStatesList',
    })
    return data?.map((st: States) => ({
      ...st,
      label: st.longName,
      value: st,
      apiId: useApiId ? st.stateId : st.longName,
    }))
  }, [])

  const getTimeZones = useCallback(async () => {
    return TimeZoneOptions.map((tz: TimeZones) => ({
      ...tz,
      value: tz.label,
      apiId: tz.value,
    }))
  }, [])

  const getMeetingCategories = useCallback(async () => {
    const { data } = await fetch({
      path: 'Options/GetMeetingCategories',
    })
    return data?.map((mc: MeetingCategories) => ({
      ...mc,
      value: mc,
      apiId: mc.value,
    }))
  }, [])

  const getAllActiveProviders = useCallback(async () => {
    const { data } = await fetch({
      path: 'Provider/GetAllActiveProviders',
    })

    if (data) {
      const allPromises = data.map(async (p: ProviderOption) => {
        if (p.photoUrl) {
          p.photoUrl = await getImageUrl(p.photoUrl)
        }
        return p
      })
      //  to ensure every item to finish, use Promise.all()
      const result = (await Promise.all(allPromises))?.map(
        (p: ProviderOption) => ({
          ...p,
          label: `${p.name} (${p.roles[0]})`,
          value: p,
          apiId: p.providerId,
        })
      )
      return result
    } else return []
  }, [])

  const getPatients = useCallback(async () => {
    const { data } = await fetch({
      path: 'Provider/GetListOfPatients',
    })
    if (data) {
      const allPromises = data.map(async (p: PatientOption) => {
        if (p.photoUrl) {
          p.photoUrl = await getImageUrl(p.photoUrl)
        }
        return p
      })
      //  to ensure every item to finish, use Promise.all()
      const result = (await Promise.all(allPromises))?.map(
        (p: PatientOption) => ({
          ...p,
          label: `${p.preferredName} (${p.mrn})`,
          value: p,
          apiId: p.patientId,
        })
      )
      return result
    } else return []
  }, [])

  const getActivePatients = useCallback(async () => {
    const { data } = await fetch({
      path: 'Provider/GetListOfActivePatients',
    })
    if (data) {
      const allPromises = data.map(async (p: PatientOption) => {
        if (p.photoUrl) {
          p.photoUrl = await getImageUrl(p.photoUrl)
        }
        return p
      })
      //  to ensure every item to finish, use Promise.all()
      const result = (await Promise.all(allPromises))?.map(
        (p: PatientOption) => ({
          ...p,
          label: `${p.preferredName} (${p.mrn})`,
          value: p,
          apiId: p.patientId,
        })
      )
      return result
    } else return []
  }, [])

  const getScheduleFrequencies = useCallback(async () => {
    const { data } = await fetch({
      path: 'Options/GetPeerGroupFrequencies',
    })
    return data
      .map((pgf: Options) => ({
        ...pgf,
        apiId: pgf.value,
        label: pgf.label,
        value: pgf.value,
      }))
      .filter((x: Options) => !!x.apiId)
  }, [])

  const getPeerGroupBackgroundOptions = useCallback(async () => {
    const { data } = await fetch({
      path: 'Options/GetBackgroundImages',
    })
    const backgroundOptions = data.map(async (pgf: BackgroundPhotoOption) => ({
      ...pgf,
      apiId: pgf.id,
      label: pgf.timesUsed ? `Used ${pgf.timesUsed} times` : undefined,
      value: pgf.id,
      storageKey: await getImageUrl(pgf.storageKey),
    }))

    return await Promise.all(backgroundOptions)
  }, [])

  // const loadAllOptions = async () => {
  //   setOptionsLoading(true)
  //   const allOptions: OptionsContainers = {
  //     providerCredentials: await getCredentialsList(),
  //     states: await getStates(),
  //     statesWithoutId: await getStates(false),
  //     meetingCategories: await getMeetingCategories(),
  //     timeZones: await getTimeZones(),
  //     providers: await getAllActiveProviders(),
  //     providerUsers: (await getAllActiveProviders()).filter((x) => x.isUser),
  //     patients: await getPatients(),
  //     activePatients: await getActivePatients(),
  //     scheduleFrequencies: await getScheduleFrequencies(),
  //     peerGroupBackgrounds: await getPeerGroupBackgroundOptions(),
  //   }
  //   setOptions(allOptions)
  //   setOptionsLoading(false)
  // }

  const getAllOptions = useCallback(async () => {
    setOptionsLoading(true)
    try {
      const { data } = await fetch({
        path: 'Options/GetAllOptions',
      })

      const processEntityWithPhoto = async <
        T extends { photoUrl?: string | null | undefined }
      >(
        entity: T
      ): Promise<T> => {
        if (entity.photoUrl) {
          entity.photoUrl = await getImageUrl(entity.photoUrl)
        }
        return entity
      }

      const [
        processedProviders,
        processedPatients,
        processedActivePatients,
        timeZones,
      ] = await Promise.all([
        Promise.all(
          data.allActiveProviders.map((p: ProviderOption) =>
            processEntityWithPhoto(p)
          )
        ),
        Promise.all(
          data.allPatients.map((p: PatientOption) => processEntityWithPhoto(p))
        ),
        Promise.all(
          data.activePatients.map((p: PatientOption) =>
            processEntityWithPhoto(p)
          )
        ),
        getTimeZones(),
      ])

      const backgroundImagesFormatted = await Promise.all(
        data.backgroundImages.map(async (pgf: BackgroundPhotoOption) => ({
          ...pgf,
          apiId: pgf.id,
          label: pgf.timesUsed ? `Used ${pgf.timesUsed} times` : undefined,
          value: pgf.id,
          storageKey: await getImageUrl(pgf.storageKey),
        }))
      )

      const formattedProviders = processedProviders.map(
        (p: ProviderOption) => ({
          ...p,
          label: `${p.name} (${p.roles[0]})`,
          value: p,
          apiId: p.providerId,
        })
      )

      const formattedPatients = processedPatients.map((p: PatientOption) => ({
        ...p,
        label: `${p.preferredName} (${p.mrn})`,
        value: p,
        apiId: p.patientId,
      }))

      const formattedActivePatients = processedActivePatients.map(
        (p: PatientOption) => ({
          ...p,
          label: `${p.preferredName} (${p.mrn})`,
          value: p,
          apiId: p.patientId,
        })
      )

      const allOptions: OptionsContainers = {
        providerCredentials: data.providerCredentials?.map(
          (cr: Credentials, i: number) => ({
            ...cr,
            label: `${cr.longName} (${cr.abbreviation})`,
            value: cr,
            apiId: cr.id,
          })
        ),
        states: data.states.map((st: States) => ({
          ...st,
          label: st.longName,
          value: st,
          apiId: st.stateId,
        })),
        statesWithoutId: data.states.map((st: States) => ({
          ...st,
          label: st.longName,
          value: st,
          apiId: st.longName,
        })),
        meetingCategories: data.meetingCategories?.map(
          (mc: MeetingCategories) => ({
            ...mc,
            value: mc,
            apiId: mc.value,
          })
        ),
        timeZones,
        providers: formattedProviders,
        providerUsers: formattedProviders.filter((x) => x.isUser),
        patients: formattedPatients,
        activePatients: formattedActivePatients,
        scheduleFrequencies: data.peerGroupFrequencies
          .map((pgf: Options) => ({
            ...pgf,
            apiId: pgf.value,
            label: pgf.label,
            value: pgf.value,
          }))
          .filter((x: Options) => !!x.apiId),
        peerGroupBackgrounds: backgroundImagesFormatted,
      }

      setOptions(allOptions)
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setOptionsLoading(false)
    }
  }, [])

  const reloadOptions = () => getAllOptions()

  useEffect(() => {
    getAllOptions()
  }, [])

  return (
    <OptionsContext.Provider
      value={{
        options,
        optionsLoading,
        reloadOptions,
      }}
    >
      {children}
    </OptionsContext.Provider>
  )
}

export const useOptions = () => useContext(OptionsContext)
