import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useAxios } from '../../utilities/Requests/useAxios'
import { getImageUrl } from '../../utilities/Storage'
import {
  sqsMessage,
  useWebSocketManager,
} from '../../utilities/useWebsocketManager'
import {
  MessageData,
  MessagesContextType,
  MessageThreadData,
  MessageUser,
  SendMessageData,
  ThreadsObj,
} from './types'

export const MessagesContext = createContext<MessagesContextType | null>(null)

export const MessagesProvider = ({ children }: { children?: ReactNode }) => {
  const { fetch } = useAxios()

  //websocket
  const WS_URL = `${process.env.REACT_APP_API_CONFIG_PATH}/ws` // our WebSocket endpoint
  const { isConnected, sqsMessages } = useWebSocketManager(WS_URL)

  const [isNewMessage, setIsNewMessage] = useState(false)

  const [threads, setThreads] = useState<ThreadsObj>({
    myPatients: [],
    providers: [],
    otherPatients: [],
    pastPatients: [], // NCA-47: Created new Message Tab
  })

  //todo: may remove soon

  const [selectedThreadId, setSelectedThreadId] = useState<number | null>(null)

  // attachments
  const [attachmentKeys, setAttachmentKeys] = useState<
    { id: number; key: string }[]
  >([])

  useEffect(() => {
    if (isConnected) {
      console.log('WebSocket connected, ready to receive messages')
      getProviderMessageThreads()
    }
  }, [isConnected])

  useEffect(() => {
    console.log(
      '🔍 MessageProvider: sqsMessages updated:',
      `now has ${sqsMessages.length} messages:`,
      sqsMessages
    )
  }, [sqsMessages])

  useEffect(() => {
    if (sqsMessages.length > 0 && threads.myPatients.length) {
      updateThreadsFromWebSocket(sqsMessages)
    }
  }, [sqsMessages])

  const updateThreadsFromWebSocket = (newMessages: sqsMessage[]) => {
    // Create a completely new copy of the threads object
    const threadsCopy = {
      myPatients: [...threads.myPatients],
      providers: [...threads.providers],
      otherPatients: [...threads.otherPatients],
      pastPatients: [...threads.pastPatients],
    }

    let hasUpdates = false

    newMessages.forEach((newMsg) => {
      // Check all thread categories to find the thread
      let threadFound = false

      // Update in each category as needed
      Object.keys(threadsCopy).forEach((category) => {
        const categoryThreads = threadsCopy[category as keyof ThreadsObj]
        const threadIndex = categoryThreads.findIndex(
          (thread) => thread.messageThreadId === newMsg.threadId
        )

        if (threadIndex !== -1) {
          threadFound = true

          console.log(
            `Updating thread ${newMsg.threadId} with websocket new message`
          )

          if (
            categoryThreads[threadIndex].messages.findIndex(
              (message) => message.messageId === newMsg.messageId
            ) !== -1
          ) {
            console.log('message already exists in thread')
            return
          }

          // Create a new thread object with the new message at the beginning
          const updatedThread = {
            ...categoryThreads[threadIndex],
            unread: true,
            messages: [
              // New message first to ensure it's the preview
              {
                messageId: newMsg.messageId,
                messageBody: newMsg.messageBody,
                fromUser: {
                  userId: newMsg.fromUserId,
                  name: newMsg.fromUser.name,
                  role: newMsg.fromUser.role,
                  photoUrl: newMsg.fromUser.photoUrl,
                  isActive: newMsg.fromUser.isActive,
                  isPatient: newMsg.fromUser.isPatient,
                } as unknown as MessageUser,
                toUsers: newMsg.toUsers?.map((u) => u),
                receivedDateTime: newMsg.receivedDateTime,
                urgent: false,
                attachments: newMsg.attachments,
                isFirstMessageInThread: false,
              },
              ...categoryThreads[threadIndex].messages,
            ],
          }

          updatedThread.messages.sort(
            (a, b) =>
              new Date(b.receivedDateTime).getTime() -
              new Date(a.receivedDateTime).getTime()
          )

          // Replace the thread with our updated version
          categoryThreads[threadIndex] = updatedThread

          hasUpdates = true
        }
      })

      if (!threadFound) {
        console.log(
          `Thread ${newMsg.threadId} not found, might need to refresh threads`
        )
        getProviderMessageThreads()
      }
    })
    // Only update state if we made changes
    if (hasUpdates) {
      console.log('Setting completely new threads object:', threadsCopy)
      setThreads(threadsCopy)
    }
  }

  const getProviderMessageThreads = async () => {
    const { data, error } = await fetch({
      path: `Message/GetProviderMessageThreads`,
    })
    if (data) {
      for (const property in data) {
        data[property].forEach(async (d: MessageThreadData) => {
          if (d.displayUser.photoUrl) {
            d.displayUser.photoUrl = await getImageUrl(d.displayUser.photoUrl)
          }

          d.messages.forEach(async (m: MessageData) => {
            if (m.fromUser.photoUrl) {
              m.fromUser.photoUrl = await getImageUrl(m.fromUser.photoUrl)
            }
            m.toUsers.forEach(async (t: MessageUser) => {
              if (t.photoUrl) {
                t.photoUrl = await getImageUrl(t.photoUrl)
              }
            })

            const notificationCount = Object.values(threads)
              .flat()
              .filter((x) => x.unread).length

            const notificationTitle = notificationCount
              ? `(${notificationCount}) NorthStar-Care Provider Portal`
              : `NorthStar-Care Provider Portal`
            document.title = notificationTitle
          })
        })
        setThreads({ ...data })
      }
      return data
    } else if (error) {
      throw new Error(`Error in getProviderMessageThreads.`)
    }
  }

  const getProviderPastPatientMessageThreads = async () => {
    const { data, error } = await fetch({
      path: `Message/GetProviderPastPatientMessageThreads`,
    })
    if (data) {
      data.forEach(async (d: MessageThreadData) => {
        if (d.displayUser.photoUrl) {
          d.displayUser.photoUrl = await getImageUrl(d.displayUser.photoUrl)
        }

        d.messages.forEach(async (m: MessageData) => {
          if (m.fromUser.photoUrl) {
            m.fromUser.photoUrl = await getImageUrl(m.fromUser.photoUrl)
          }
          m.toUsers.forEach(async (t: MessageUser) => {
            if (t.photoUrl) {
              t.photoUrl = await getImageUrl(t.photoUrl)
            }
          })
        })
      })
      console.log('data', data)
      const threadsWithPastPatientsAdded = { ...threads, pastPatients: data }
      setThreads(threadsWithPastPatientsAdded)

      return data
    } else if (error) {
      throw new Error(`Error in getProviderMessageThreads.`)
    }
  }

  // get all the messages in one thread
  const getProviderMessagesInThread = async (
    threadId: number,
    messageId: number
  ) => {
    const { data, error } = await fetch({
      path: `Message/GetProviderMessagesInThread?messageThreadId=${threadId}&messageId=${messageId}`,
    })
    if (data) {
      const threadsCopy = {
        myPatients: [...threads.myPatients],
        providers: [...threads.providers],
        otherPatients: [...threads.otherPatients],
        pastPatients: [...threads.pastPatients],
      }

      // Update in each category as needed
      Object.keys(threadsCopy).forEach((category) => {
        const categoryThreads = threadsCopy[category as keyof ThreadsObj]
        const threadIndex = categoryThreads.findIndex(
          (thread) => thread.messageThreadId === threadId
        )

        if (threadIndex !== -1) {
          // Create a new thread object with the new message at the beginning
          const updatedThread = {
            ...categoryThreads[threadIndex],
            unread: true,
            messages: [
              ...categoryThreads[threadIndex].messages,
              ...data.messages,
            ],
          }

          const uniqueMessages = updatedThread.messages.filter(
            (message, index, arr) =>
              arr.findIndex((item) => item.messageId === message.messageId) ===
              index
          )

          updatedThread.messages = uniqueMessages

          updatedThread.messages.sort(
            (a, b) =>
              new Date(b.receivedDateTime).getTime() -
              new Date(a.receivedDateTime).getTime()
          )
          // Replace the thread with our updated version
          categoryThreads[threadIndex] = updatedThread

          setThreads(threadsCopy)
        }
      })
      return { ...data }
    } else if (error) {
      console.log('this is the error happening?')
      throw new Error(`Error in getProviderMessagesInThread.`)
    }
  }
  useEffect(() => {
    console.log('selectedthreadid', selectedThreadId)
  }, [selectedThreadId])

  const sendMessage = async (sendMessageData: SendMessageData) => {
    const { error } = await fetch({
      path: `Message/SendProviderMessage`,
      methodType: 'POST',
      body: sendMessageData,
    })
    // getProviderMessageThreads()
    if (error) {
      throw new Error(`Error in sendMessage.`)
    }
  }

  return (
    <MessagesContext.Provider
      value={{
        threads,
        getProviderMessageThreads,
        selectedThreadId,
        setSelectedThreadId,
        getProviderMessagesInThread,
        getProviderPastPatientMessageThreads,
        sendMessage,
        attachmentKeys,
        setAttachmentKeys,
        isNewMessage,
        setIsNewMessage,
      }}
    >
      {children}
    </MessagesContext.Provider>
  )
}

export const useMessages = () =>
  useContext(MessagesContext) as MessagesContextType
