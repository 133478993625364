import { useState } from 'react'

import { useAnnouncement } from '../../../../providers/Announcement/Announcement.provider'
import { AnnouncementListItem } from '../../../../providers/Announcement/types'
import useInterval from '../../../../utilities/useIntervals'
import { AnnouncementView } from './Announcement.view'

const Announcement = () => {
  const { announcements, getListOfAnnouncements } = useAnnouncement()

  useInterval(() => {
    getListOfAnnouncements()
  }, 60000)

  const [showViewAnnouncementModal, setShowViewAnnouncementModal] =
    useState<boolean>(false)

  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<AnnouncementListItem>({
      id: 0,
      dateSent: '',
      description: '',
      name: '',
      sentBy: '',
    })

  const handleEditProviderModal = (selectedAnnouncementId: number) => {
    const selectedAnnouncement = announcements?.find(
      (t) => t.id === selectedAnnouncementId
    )
    if (selectedAnnouncement) {
      setSelectedAnnouncement(selectedAnnouncement)
      setShowViewAnnouncementModal(true)
    }
  }

  return (
    <AnnouncementView
      {...{
        showViewAnnouncementModal,
        setShowViewAnnouncementModal,
        selectedAnnouncement,
        handleEditProviderModal,
      }}
      deliveredAnnouncements={announcements}
    />
  )
}

export { Announcement }
