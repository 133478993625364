import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useAxios } from '../../utilities/Requests/useAxios'
import { getImageUrl } from '../../utilities/Storage'
import { VrHeadsetContextType } from './types'

export const VrHeadsetContext = createContext<VrHeadsetContextType>({
  isPaired: false,
  deviceId: null,
  pairingCode: null,
  getPairingCode: async () => Promise,
  unpairHeadset: async () => Promise,
  pairingInstructionVideo: null,
  joinInstructionVideo: null,
  unpairError: null,
})

export const VrHeadsetProvider = ({ children }: { children?: ReactNode }) => {
  const [vrHeadsetPaired, setVrHeadsetPaired] = useState<boolean>(false)
  const [deviceId, setDeviceId] = useState<string | null>(null)
  const [pairingCode, setPairingCode] = useState<string | null>(null)
  const [unpairError, setUnpairError] = useState<string | null>(null)
  const [pairingInstructionVideo, setPairingInstructionVideo] = useState<
    string | null
  >(null)
  const [joinInstructionVideo, setJoinInstructionVideo] = useState<
    string | null
  >(null)

  const { fetch } = useAxios()

  const getPairingStatus = useCallback(async () => {
    const { data } = await fetch({
      path: 'Headset/GetPairingStatus',
    })
    if (data) {
      setVrHeadsetPaired(data.isPaired)
      setDeviceId(data.isPaired ? data.deviceId : null)
      return data.isPaired
    }
    return false
  }, [])

  const getPairingCode = useCallback(async () => {
    const { data } = await fetch({
      path: 'Headset/RequestPairingCode',
      methodType: 'POST',
    })
    if (data) {
      setPairingCode(data.pairingCode)
      const interval = setInterval(async () => {
        const pairedSuccessfully = await getPairingStatus()
        if (pairedSuccessfully) {
          clearInterval(interval)
          setPairingCode(null)
        }
      }, 15000)
    }
  }, [])

  const unpairHeadset = useCallback(async () => {
    const { data, error } = await fetch({
      path: 'Headset/Unpair',
      methodType: 'POST',
      body: { deviceId },
    })
    if (data) {
      if (data.unpaired) {
        setVrHeadsetPaired(false)
        setDeviceId(null)
      }
    }
    if (error) {
      setUnpairError('Error unpairing device')
    }
  }, [deviceId])

  const getTutorialAssets = useCallback(async () => {
    const pairingInstructionVidKey =
      'tutorial-assets/Video Tutorial - Pair Headset & NSC Account.mp4'
    const joinInstructionVidKey =
      'tutorial-assets/Video Tutorial - Join VR Peer Support Groups.mp4'
    const pairingInstructionVideo = await getImageUrl(pairingInstructionVidKey)
    const joinInstructionVideo = await getImageUrl(joinInstructionVidKey)
    setPairingInstructionVideo(pairingInstructionVideo)
    setJoinInstructionVideo(joinInstructionVideo)
  }, [])

  useEffect(() => {
    getPairingStatus()
    getTutorialAssets()
  }, [])

  return (
    <VrHeadsetContext.Provider
      value={{
        isPaired: vrHeadsetPaired,
        deviceId,
        pairingCode,
        getPairingCode,
        unpairHeadset,
        pairingInstructionVideo,
        joinInstructionVideo,
        unpairError,
      }}
    >
      {children}
    </VrHeadsetContext.Provider>
  )
}

export const useVrHeadset = () => useContext(VrHeadsetContext)
