import {
  Group as GroupIcon,
  MoreVert as MoreVertIcon,
  Notifications as NotificationsIcon,
  Shield as ShieldIcon,
} from '@mui/icons-material'
import { Box, Button, IconButton, Tab, Tabs, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import {
  NotificationsContext,
  NotificationsContextInterface,
} from '../../providers/Notifications/NotificationsProvider'
import { NotificationBlock } from './NotificationBlock'

// Define types for our notification data
type IconType = 'shield' | 'users' | 'bell'

type NotificationsDisplayProps = {
  handleCloseNotifications: () => void
}

export const NotificationsDisplay = ({
  handleCloseNotifications,
}: NotificationsDisplayProps) => {
  const {
    notifications,
    unreadNotifications,
    unreadNotificationCount,
    getNotifications,
    getMoreNotifications,
    getUnreadNotifications,
    getMoreUnreadNotifications,
    notificationsEnd,
    unreadNotificationsEnd,
  } = useContext(NotificationsContext) as NotificationsContextInterface

  const [activeTab, setActiveTab] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  // Initial data loading
  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true)
      try {
        await getNotifications()
      } catch (error) {
        console.error('Failed to load notifications:', error)
      } finally {
        setIsLoading(false)
      }
    }

    loadInitialData()
  }, [])

  const handleTabChange = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setActiveTab(newValue)

    if (newValue === 1 && unreadNotifications.length === 0) {
      setIsLoading(true)
      try {
        await getUnreadNotifications()
      } catch (error) {
        console.error('Failed to load unread notifications:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleSeeMore = async () => {
    setIsLoading(true)
    try {
      if (activeTab === 0 && notifications.length > 0) {
        const oldestNotification = [...notifications].sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        )[0]
        await getMoreNotifications(oldestNotification.id)
      } else if (activeTab === 1 && unreadNotifications.length > 0) {
        const oldestNotification = [...unreadNotifications].sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        )[0]
        await getMoreUnreadNotifications(oldestNotification.id)
      }
    } catch (error) {
      console.error('Failed to load more notifications:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const displayNotifications =
    activeTab === 0 ? notifications : unreadNotifications
  const isEndOfList =
    activeTab === 0 ? notificationsEnd : unreadNotificationsEnd

  return (
    <Box sx={{ p: 0 }}>
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1.5,
          borderBottom: '1px solid #eee',
        }}
      >
        <Typography
          variant='subtitle1'
          fontWeight='bold'
          sx={{ fontSize: '16px' }}
        >
          Notifications
        </Typography>
      </Box>

      {/* Tabs */}
      <Box sx={{ px: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            mt: 1,
            mb: 0,
            minHeight: '32px',
            '& .MuiTabs-flexContainer': {
              gap: '8px',
            },
          }}
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          <Tab
            label='All'
            sx={{
              borderRadius: '16px',
              minWidth: 'unset',
              px: 2,
              py: 0.25,
              mr: 0, // Remove right margin
              fontSize: '13px',
              bgcolor: activeTab === 0 ? '#E7F3FF' : '#F0F2F5',
              color: activeTab === 0 ? '#1877F2' : '#606770',
              '&.Mui-selected': {
                color: '#1877F2',
              },
              textTransform: 'none',
              fontWeight: 500,
              minHeight: '32px',
            }}
          />
          <Tab
            label={`Unread${
              unreadNotificationCount ? ` (${unreadNotificationCount})` : ''
            }`}
            sx={{
              borderRadius: '16px',
              minWidth: 'unset',
              px: 2,
              py: 0.25,
              fontSize: '13px',
              bgcolor: activeTab === 1 ? '#E7F3FF' : '#F0F2F5',
              color: activeTab === 1 ? '#1877F2' : '#606770',
              '&.Mui-selected': {
                color: '#1877F2',
              },
              textTransform: 'none',
              fontWeight: 500,
              minHeight: '32px',
            }}
          />
        </Tabs>
      </Box>

      {isLoading && (
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <Typography variant='body2' color='text.secondary'>
            Loading notifications...
          </Typography>
        </Box>
      )}

      {!isLoading && displayNotifications.length > 0 && (
        <Box sx={{ px: 2, mt: 0.5, mb: 1 }}>
          {displayNotifications.map((notification) => (
            <NotificationBlock
              handleCloseNotifications={handleCloseNotifications}
              key={notification.id}
              notification={notification}
            />
          ))}
        </Box>
      )}

      {!isLoading && displayNotifications.length === 0 && (
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <Typography variant='body2' color='text.secondary'>
            {activeTab === 0 ? 'No notifications' : 'No unread notifications'}
          </Typography>
        </Box>
      )}

      {!isLoading && displayNotifications.length > 0 && (
        <Box sx={{ p: 0.25, pb: 1.5, mt: 0 }}>
          <Button
            fullWidth
            variant='text'
            disabled={isEndOfList}
            sx={{
              py: 1,
              bgcolor: '#F0F2F5',
              color: '#050505',
              textTransform: 'none',
              fontWeight: 500,
              borderRadius: 1.5,
              fontSize: '13px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '& .MuiButton-startIcon': { marginRight: 0 },
              '& .MuiButton-endIcon': { marginLeft: 0 },
              '&:hover': {
                bgcolor: '#E4E6E9',
              },
            }}
            onClick={handleSeeMore}
          >
            {isEndOfList ? 'End of Notifications' : 'See more'}
          </Button>
        </Box>
      )}
    </Box>
  )
}
