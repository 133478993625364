import { CircularProgress } from '@mui/material'
import {
  addDays,
  addMonths,
  addWeeks,
  format,
  getWeek,
  isSameDay,
  lastDayOfWeek,
  startOfWeek,
  subMonths,
  subWeeks,
} from 'date-fns'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { useSchedule } from '../../providers/Schedule/Schedule.provider'
import { Appointment } from '../../providers/Schedule/types'

interface WeeklyScheduleCalendarInterface {
  patientId: number
}

export const WeeklyScheduleCalendar = ({
  patientId,
}: WeeklyScheduleCalendarInterface) => {
  const { getPatientSchedule, patientSchedule } = useSchedule()

  // test styles
  // const mockSchedule = [
  //   { id: 1, appointmentName: 'Friday mental health meeting' },
  //   { id: 1, appointmentName: 'Monday quick check in ' },
  //   { id: 1, appointmentName: 'Peer group discussion' },
  // ]

  const [currentMonth, setCurrentMonth] = useState(new Date())
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth))
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [scheduleLoading, setScheduleLoading] = useState<boolean>(false)

  const getData = async () => {
    setScheduleLoading(true)
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 })
    await getPatientSchedule(startDate, patientId)
    setScheduleLoading(false)
  }

  useEffect(() => {
    getData()
  }, [patientId, currentMonth])

  const changeMonthHandle = (btnType: string) => {
    if (btnType === 'prev') {
      setCurrentMonth(subMonths(currentMonth, 1))
    }
    if (btnType === 'next') {
      setCurrentMonth(addMonths(currentMonth, 1))
    }
  }

  const changeWeekHandle = (btnType: string) => {
    if (btnType === 'prev') {
      setCurrentMonth(subWeeks(currentMonth, 1))
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)))
    }
    if (btnType === 'next') {
      setCurrentMonth(addWeeks(currentMonth, 1))
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)))
    }
  }

  const onDateClickHandle = (day: Date, dayStr: string) => {
    setSelectedDate(day)
  }

  const renderHeader = () => {
    const dateFormat = 'MMM yyyy'
    return (
      <div className='weekly-calendar-header row flex-middle'>
        <div className='col col-start'>
          <button
            type='button'
            className='weekBtn'
            onClick={() => changeMonthHandle('prev')}
          >
            {'<'} Prev Month
          </button>
        </div>
        <div className='col col-center'>
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className='col col-end'>
          <button
            type='button'
            className='weekBtn'
            onClick={() => changeMonthHandle('next')}
          >
            {'>'} Next Month
          </button>
        </div>
      </div>
    )
  }

  const renderDays = () => {
    const dateFormat = 'EEE'
    const days = []
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 })
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className='col col-center' key={`col-day-${i}`}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      )
    }
    return <div className='days row'>{days}</div>
  }

  const renderMeetingClassname = (appointment: Appointment): string => {
    const roleToClassMap = {
      Peer: 'appointment-name-darkBlue',
      Nurse: 'appointment-name-tiffany',
      Navigator: 'appointment-name-orange',
      Doc: 'appointment-name-purple',
      APC: 'appointment-name-purple',
      Psych: 'appointment-name-green',
      Coach: 'appointment-name-gold',
      Therapist: 'appointment-name-gold',
      Ally: 'appointment-name-gold',
    }
    for (const participant of appointment.participants) {
      for (const [role, className] of Object.entries(roleToClassMap)) {
        if (
          participant.participantRole.toLowerCase().includes(role.toLowerCase())
        ) {
          return className
        }
      }
    }
    return ''
  }

  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 })
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 })
    const dateFormat = 'd'
    const rows = []
    let days = []
    let day = startDate
    let formattedDate = ''

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat)
        const cloneDay = day
        const patientDaySchedule = patientSchedule.filter(
          (appointment: Appointment) => {
            return dayjs(cloneDay).isSame(dayjs(appointment.startTime), 'day')
          }
        )
        days.push(
          <div
            className={`col cell ${
              isSameDay(day, new Date())
                ? 'today'
                : isSameDay(day, selectedDate)
                ? 'selected'
                : ''
            }`}
            key={`weekly-calendar-${i}`}
          >
            <button
              type='button'
              onClick={() => {
                const dayStr = format(cloneDay, 'ccc dd MMM yy')
                onDateClickHandle(cloneDay, dayStr)
              }}
              className='day-button'
            >
              <section className='day-appointment-wrapper'>
                {patientDaySchedule.map((appointment: Appointment) => {
                  return (
                    <span
                      className={`appointment-name ${renderMeetingClassname(
                        appointment
                      )}`}
                      key={`weekly-calendar-${appointment.appointmentId}`}
                    >
                      {dayjs(appointment.startTime).format('h:mm A')}
                      {' - '}
                      {appointment.appointmentName}
                    </span>
                  )
                })}
              </section>

              <span className='number'>{formattedDate}</span>
              <span className='bg'>{formattedDate}</span>
            </button>
          </div>
        )
        day = addDays(day, 1)
      }

      rows.push(
        <div className='row' key={`weekly-calendar-row-${days}`}>
          {days}
        </div>
      )
      days = []
    }
    return <div className='weekly-calendar-body'>{rows}</div>
  }

  const renderFooter = () => {
    return (
      <div className='weekly-calendar-header row flex-middle'>
        <div className='col col-start'>
          <div>
            <button
              type='button'
              className='weekBtn'
              onClick={() => changeWeekHandle('prev')}
            >
              {'<'} Prev Week
            </button>
          </div>
        </div>
        <div>Week {currentWeek}</div>
        <div className='col col-end'>
          <button
            type='button'
            className='weekBtn'
            onClick={() => changeWeekHandle('next')}
          >
            Next Week {'>'}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className='calendar' style={{ position: 'relative' }}>
      {scheduleLoading ? (
        <CircularProgress
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      ) : null}
      {renderHeader()}
      {renderDays()}
      {renderCells()}
      {renderFooter()}
    </div>
  )
}
