import { Box, Button } from '@mui/material'
import { useState } from 'react'

import { PatientOrProviderDetails } from '../../../components/PatientOrProviderDetails/PatientOrProviderDetails'
import { PatientOrProvider } from '../../../components/PatientOrProviderDetails/types'
import { AnnouncementProvider } from '../../../providers/Announcement/Announcement.provider'
import {
  MessagesProvider,
  useMessages,
} from '../../../providers/Messages/Messages.provider'
import { PeerGroupsProvider } from '../../../providers/PeerGroups/PeerGroups.provider'
import { useProviderDetails } from '../../../providers/ProviderDetails/ProviderDetails.provider'
import {
  ScheduleProvider,
  useSchedule,
} from '../../../providers/Schedule/Schedule.provider'
import { VrHeadsetProvider } from '../../../providers/VrHeadset/VrHeadset.provider'
import { Announcement } from './Announcement/Announcement.container'
import { Schedule } from './Schedule/Schedule.container'
// NEW WIDGET IMPORT
import { Widget } from './types'

export const DashboardWidgets: Widget[] = [
  {
    name: 'Schedule',
    element: (
      <VrHeadsetProvider>
        <PeerGroupsProvider>
          <Schedule />
        </PeerGroupsProvider>
      </VrHeadsetProvider>
    ),
    Provider: ScheduleProvider,
    height: 'half',
    width: 'half',
    usePadding: false,
    Buttons: [
      () => {
        const { scheduleModeInverse, setScheduleMode } = useSchedule()

        return (
          <Box>
            <Button
              variant='contained'
              onClick={() => {
                setScheduleMode(scheduleModeInverse)
              }}
            >
              View {scheduleModeInverse}
            </Button>
          </Box>
        )
      },
      () => {
        const { myProviderDetails, myContact, providerInformation } =
          useProviderDetails()
        const [anchor, setAnchor] = useState<null | HTMLElement>(null)
        const id = anchor ? 'schedule-links' : undefined

        const [myPopover, setMyPopover] = useState(false)

        return (
          <Box>
            <Button
              variant='contained'
              onClick={(e) => {
                setAnchor(e.currentTarget)
                setMyPopover(true)
              }}
              aria-describedby={id}
            >
              Get Schedule Links
            </Button>
            <PatientOrProviderDetails
              id={id}
              open={!!anchor}
              handleClose={() => {
                setAnchor(null)
                setMyPopover(false)
              }}
              anchorEl={anchor}
              type={PatientOrProvider.Provider}
              user={{
                name: `${
                  myProviderDetails?.preferredFirstName ||
                  myProviderDetails?.firstName
                } ${myProviderDetails?.lastName}`,
                photoUrl: myProviderDetails?.photoUrl,
                providerRole: myProviderDetails?.role,
                patientOrProvider: PatientOrProvider.Provider,
              }}
              contact={myContact!}
              myPopover={myPopover}
              providerInformation={providerInformation}
            />
          </Box>
        )
      },
    ],
  },
  {
    name: 'Announcements',
    element: <Announcement />,
    Provider: AnnouncementProvider,
    height: 'half',
    width: 'half',
  },
  {
    name: 'Messages',
    // element: <Messaging />, // Pre-created, lacking re-render trigger
    element: 'Messaging', // Just the component reference
    Provider: MessagesProvider,
    height: 'full',
    width: 'half',
    Buttons: [
      () => {
        const { setIsNewMessage } = useMessages()
        return (
          <Button variant='outlined' onClick={() => setIsNewMessage(true)}>
            New Message
          </Button>
        )
      },
    ],
  },
  // NEW WIDGET SPEC
]
