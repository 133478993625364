import {
  AccountCircle,
  Assignment,
  EventNote,
  Forum,
  Home,
  RateReview,
  Reply,
  SupervisorAccount,
  Tune,
} from '@mui/icons-material'
import { createContext, ReactNode, useContext, useState } from 'react'

import { PatientChartPage } from '../../components/PatientChart/PatientChartPage'
import { Admin } from '../../views/Admin/Admin.container'
import { Patients } from '../../views/Admin/Patients/Patients.container'
import { PeerGroups } from '../../views/Admin/PeerGroups/PeerGroups.container'
import { Dashboard } from '../../views/Dashboard/Dashboard.container'
import Messaging from '../../views/Dashboard/Widgets/Messaging/Messaging.container'
import { Profile } from '../../views/Profile/Profile.container'
import { Schedule } from '../../views/Schedule/Schedule.container'
import { MessagesProvider } from '../Messages/Messages.provider'
import { VrHeadsetProvider } from '../VrHeadset/VrHeadset.provider'
import { Navigation, NavigationContextType } from './types'

export const NavigationContext = createContext<NavigationContextType | null>(
  null
)

export const NavigationProvider = ({ children }: { children?: ReactNode }) => {
  const navigationLinks = [
    {
      Icon: <Home />,
      title: 'Home',
      route: 'home',
      View: <Dashboard />,
      useViewPaper: false,
    },
    {
      Icon: <Tune />,
      title: 'My Patients',
      route: 'myPatients',
      View: <Patients />,
    },
    {
      Icon: <Tune />,
      title: 'Peer Groups',
      route: 'peerGroups',
      View: <PeerGroups />,
    },
    {
      Icon: <EventNote />,
      title: 'Schedule',
      route: 'schedule',
      View: <Schedule />,
      restricted: true,
    },
    {
      Icon: <RateReview />,
      title: 'Messages',
      route: 'messages',
      restricted: true,
    },
    {
      Icon: <Assignment />,
      title: 'Tasks',
      route: 'tasks',
      restricted: true,
    },
    {
      Icon: <SupervisorAccount />,
      title: 'Patients',
      route: 'patients',
      restricted: true,
    },
    {
      Icon: <Forum />,
      title: 'Message with...',
      route: 'messages/:routedThreadId',
      View: <Messaging isStandAloneView />,
      Provider: MessagesProvider,
      useViewPaper: false,
      hiddenFromNav: true,
    },
    {
      Icon: <Reply />,
      title: 'Reply',
      route: 'message/:routedMessageId',
      View: <Messaging isStandAloneView />,
      useViewPaper: false,
      Provider: MessagesProvider,
      hiddenFromNav: true,
    },
    {
      Icon: <SupervisorAccount />,
      title: 'Patient Chart',
      route: 'patientChart/:routedPatientId',
      View: <PatientChartPage />,
      hiddenFromNav: true,
    },
    {
      Icon: <AccountCircle />,
      title: 'Profile',
      route: 'account',
      View: <Profile />,
      Provider: VrHeadsetProvider,
      hiddenFromNav: true,
    },
  ]

  const adminLink = {
    Icon: <Tune />,
    title: 'Admin',
    route: 'admin',
    View: <Admin />,
  }

  const [navigation, setNavigation] = useState<Navigation>({
    navigationLinks,
    allRoutes: [...navigationLinks, adminLink],
  })

  return (
    <NavigationContext.Provider
      value={{
        navigation,
        setNavigation,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}
export const useNavigation = () => {
  const context = useContext(NavigationContext)

  if (context === null) {
    return {
      navigation: {
        navigationLinks: [],
        allRoutes: [],
      },
      setNavigation: () => {},
    }
  }

  return context
}
