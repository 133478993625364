import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useAxios } from '../../utilities/Requests/useAxios'
import {
  AnnouncementContextInterface,
  AnnouncementListResponseData,
  SendAnnouncementRequestData,
  UpdatedAnnouncementData,
} from './types'

export const AnnouncementContext =
  createContext<AnnouncementContextInterface | null>(null)

export const AnnouncementProvider = ({
  children,
}: {
  children?: ReactNode
}) => {
  const [announcements, setAnnouncements] =
    useState<AnnouncementListResponseData>([])

  const { fetch } = useAxios()

  const getListOfAnnouncements = useCallback(async () => {
    const { data } = await fetch({
      path: 'Announcements/GetListOfAllAnnouncements',
    })
    if (data) {
      setAnnouncements(data)
    }
  }, [])

  const sendNewAnnouncement = async (
    announcementRequestData: SendAnnouncementRequestData
  ) => {
    const { error } = await fetch({
      path: `Announcements/AddNewAnnouncement`,
      methodType: 'POST',

      body: announcementRequestData,
    })
    getListOfAnnouncements()
    console.log('error', error)
    if (error) {
      throw new Error(`Error in sendNewAnnouncement.`)
    }
  }

  const updateAnnouncement = async (
    updatedAnnouncementData: UpdatedAnnouncementData
  ) => {
    const { error } = await fetch({
      path: `Announcements/UpdateAnnouncement`,
      methodType: 'PUT',
      body: updatedAnnouncementData,
    })
    getListOfAnnouncements()
    console.log('error', error)
    if (error) {
      throw new Error(`Error in updateAnnouncement.`)
    }
  }

  useEffect(() => {
    getListOfAnnouncements()
  }, [])

  return (
    <AnnouncementContext.Provider
      value={{
        announcements,
        sendNewAnnouncement,
        getListOfAnnouncements,
        updateAnnouncement,
      }}
    >
      {children}
    </AnnouncementContext.Provider>
  )
}

export const useAnnouncement = () =>
  useContext(AnnouncementContext) as AnnouncementContextInterface
