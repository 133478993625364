import { CognitoUser } from '@aws-amplify/auth'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

export interface UserContextType {
  user: CognitoUser | null
  token: string
  signOut: (data?: any) => void
}

export const UserContext = createContext<UserContextType>({
  user: null,
  token: '',
  signOut: () => {},
})

interface UserProviderProps {
  children?: ReactNode
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const { user, signOut } = useAuthenticator((context) => [
    context.user,
    context.signOut,
  ])

  const [token, setToken] = useState('')

  const getToken = () => {
    if (user) {
      try {
        user.getSession((error: null, session: CognitoUserSession) => {
          if (error) {
            throw new Error('User must be logged in to connect to websocket')
          }
          const token = session.getIdToken().getJwtToken()
          if (token !== '') {
            setToken(token)
          }
          return token
        })
      } catch (e) {
        console.log(e)
        return ''
      }
    }
  }

  useEffect(() => {
    getToken()
  }, [user])

  return (
    <UserContext.Provider
      value={{
        user,
        token,
        signOut,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
