import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => ({
  toolbar: {
    minHeight: theme.spacing(6),
    height: theme.spacing(6),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoContainer: {
    minHeight: theme.spacing(4),
    height: theme.spacing(3),
    margin: theme.spacing(1),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(1.5),

    '& img': {
      maxHeight: theme.spacing(3.5),
      margin: theme.spacing(0.25),
      width: '99%',
      objectFit: 'fill',
      color: theme.palette.primary.contrastText,
    },
  },
  accountContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  badgeNumber: {
    backgroundColor: 'red',
    color: 'white',
    padding: '3px',
    fontSize: '12px',
    border: '1px solid transparent',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
