import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  notificationObj,
  NotificationsContext,
  NotificationsContextInterface,
} from '../../providers/Notifications/NotificationsProvider'

// Function to format relative time
const formatRelativeTime = (dateString: string): string => {
  const date = new Date(dateString)
  const now = new Date()
  // Use getTime() to get timestamps in milliseconds (numeric values)
  const diffMs = now.getTime() - date.getTime()
  const diffSec = Math.floor(diffMs / 1000)
  const diffMin = Math.floor(diffSec / 60)
  const diffHour = Math.floor(diffMin / 60)
  const diffDay = Math.floor(diffHour / 24)
  const diffWeek = Math.floor(diffDay / 7)
  const diffMonth = Math.floor(diffDay / 30)

  if (diffSec < 60) {
    return 'Just now'
  } else if (diffMin < 60) {
    return `${diffMin} ${diffMin === 1 ? 'minute' : 'minutes'} ago`
  } else if (diffHour < 24) {
    return `${diffHour} ${diffHour === 1 ? 'hour' : 'hours'} ago`
  } else if (diffDay < 7) {
    return `${diffDay} ${diffDay === 1 ? 'day' : 'days'} ago`
  } else if (diffWeek < 4) {
    return `${diffWeek} ${diffWeek === 1 ? 'week' : 'weeks'} ago`
  } else if (diffMonth < 12) {
    return `${diffMonth} ${diffMonth === 1 ? 'month' : 'months'} ago`
  } else {
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
  }
}

type NotificationBlockProps = {
  notification: notificationObj
  handleCloseNotifications: () => void
}

export const NotificationBlock = ({
  notification,
  handleCloseNotifications,
}: NotificationBlockProps) => {
  const { markNotificationRead } = useContext(
    NotificationsContext
  ) as NotificationsContextInterface
  const go = useNavigate()

  const handleNotificationClick = () => {
    if (notification.patientId) {
      handleCloseNotifications()
      markNotificationRead(notification.id)
      go(`/patientChart/${notification.patientId}`)
    }
  }

  // Format the date to relative time
  const relativeTime = formatRelativeTime(notification.date)

  return (
    <Box
      key={notification.id}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        py: 0.75,
        '&:hover': {
          bgcolor: '#F5F6F7',
        },
        borderRadius: 1,
        px: 1,
        cursor: 'pointer',
      }}
      onClick={handleNotificationClick}
    >
      <Box sx={{ flex: 1 }}>
        <Typography sx={{ fontWeight: 500, fontSize: '13px' }}>
          {notification.content}
        </Typography>
        <Typography color='#65676B' sx={{ fontSize: '11px' }}>
          {relativeTime}
        </Typography>
      </Box>
      {!notification.isRead && (
        <Box
          sx={{
            width: 8,
            height: 8,
            bgcolor: '#1877F2',
            borderRadius: '50%',
            ml: 1,
            alignSelf: 'center',
          }}
        />
      )}
    </Box>
  )
}
